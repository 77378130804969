export interface Address {
	street: string;
	city: string;
	state: string;
	zip: string;
}

export class String {
	public static toAddress(address: string): Address {
		if (!address || !address.length) {
			return {
				street: undefined,
				city: undefined,
				state: undefined,
				zip: undefined
			};
		}
		// Remove double spaces.
		address = address.replace(/\s\s/g, ' ');
		// Remove comma space.
		address = address.replace(/\s,/g, ',');
		// Remove junk whitespace.
		address = address.trim();
		let street, city, state, zip;

		// Parse what we can.
		try {
			let stateZip = address
				.substring(address.lastIndexOf(',') + 1, address.length)
				.trim()
				.split(' ');
			let streetCity = address.substring(0, address.lastIndexOf(',')).trim();

			street = streetCity.substring(0, streetCity.lastIndexOf(',')).trim();
			city = streetCity.substring(streetCity.lastIndexOf(',') + 1, streetCity.length).trim();
			state = stateZip[0];
			zip = stateZip[1];
		} catch (err) {
			console.log(err);
		}

		return {
			street,
			city,
			state,
			zip
		};
	}

	public static similar(a: string, b: string): string {
		let equivalency = 0;
		const minLength = a.length > b.length ? b.length : a.length;
		const maxLength = a.length < b.length ? b.length : a.length;
		for (var i = 0; i < minLength; i++) {
			if (a[i] == b[i]) {
				equivalency++;
			}
		}

		var weight = equivalency / maxLength;
		return weight * 100 + '%';
	}

	public static toCurrencyString(num: number): string {
		return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
	}
	public static slugify(input: any) {
		if (!input) {
			input = '';
		}
		if (typeof input !== 'string') {
			if (input.toString) {
				input = input.toString();
			} else {
				input = `${input}`;
			}
		}
		return input
			.trim()
			.toLowerCase()
			.replace(/\s/g, '_');
	}

	/**
	 * Roughly match Strapi's slugify function
	 */
	public static strapiSlugify(input: string): string {
			return input
				.trim()
				.toLowerCase()
				.normalize('NFD') // separate letters and diacritics
				.replace(/[\u0300-\u036f]/g, '') // remove diacritics
				.replace(/[^a-z0-9\s-]/g, ' ') // remove non-alphanumerics
				.replace(/[\s-]+/g, '-'); // replace spaces or hyphens with a single hyphen
	}

	public static removeBrackets(value: any): string {
		if (typeof value != "string") return value;
		return value.replace(/\[|\]|-|\(|\)/gi, "");
	}


	public static isEmpty(value: any): boolean {
		return value === '' || value === undefined || value === null;
	}

	/**
	 * Mask a string with a specified number of unmasked characters at the end or beginning of the string
	 * @param str
	 * @param unmaskedChars
	 * @param maskPosition
	 * @param maskChar
	 */
	public static maskString(str: string, unmaskedChars: number = 2, maskPosition: 'beginning' | 'end' = 'beginning', maskChar = '*'): string {
		if (!str) {
			return str;
		}

		if (unmaskedChars >= str.length) {
			return str;
		}

		const maskLength = str.length - unmaskedChars;
		const mask = maskChar.repeat(maskLength);

		if (maskPosition === 'beginning') {
			return mask + str.slice(maskLength);
		} else {
			return str.slice(0, unmaskedChars) + mask;
		}
	}
}
