<div [formGroup]="form">
	<label>{{ label }}</label>
	<div class="pt-2" formArrayName="items">
	  <div *ngIf="showHeader && dataType !== 'delimited'" class="row headers">
		<div class="header">{{ keyLabel }}</div>
		<div class="header">{{ valueLabel }}</div>
		<div class="shim"></div>
	  </div>

	  <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i" class="row">
		<ng-container *ngIf="dataType !== 'delimited' && !keyOptions?.length">
		  <input formControlName="key" [placeholder]="keyLabel">
		</ng-container>
		<ng-container *ngIf="dataType !== 'delimited' && keyOptions?.length">
		  <mat-select formControlName="key" [placeholder]="keyLabel">
			<mat-option *ngFor="let option of keyOptions" [value]="option.value">{{ option.label }}</mat-option>
		  </mat-select>
		</ng-container>

		  <ng-container *ngIf="selection">
			  <app-select-input
				  [controlName]="'value'"
				  [options]="selection"
				  [currentValue]="item.value"
				  (valueChange)="updateFormValue($event.value, i)"
			  ></app-select-input>
		  </ng-container>

		  <div *ngIf="!selection" class="input-wrapper">
			  	<input formControlName="value" [placeholder]="valueLabel">

			  	<app-set-locale-edit-button
					*ngIf="showLocale"
					[localeData]="localeData?.[item.value.key]?.[item.value.value]"
					(localeChange)="updateLocale(item.value.key, item.value.value, $event)"
				></app-set-locale-edit-button>
		  </div>

		  <ng-container *ngIf="valueType === 'color'">
			  <div
				  class="color-picker"

				  colorPicker
				  (colorPickerChange)="updateFormValue($event, i)"

				  [style.background-color]="item.value?.value || 'none'"
			  >
				  <mat-icon *ngIf="!item.value?.value"  class="m-0">colorize</mat-icon>
			  </div>
		  </ng-container>

		<button *ngIf="showRemoveButton" mat-button class="no-padding" type="button" (click)="removeRow(i)"><mat-icon class="m-0">delete</mat-icon></button>
	  </div>
	  <div *ngIf="items.controls.length === 0 && showNoDataMessage" class="no-data">
		{{ noDataMessage }}
	  </div>
	</div>

	<button *ngIf="showAddButton" mat-button type="button" (click)="addNewRow()">{{ addLabel }}</button>
  </div>
