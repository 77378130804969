import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { GlobalStore, GlobalState, createInitialState } from './global.store';
import {
	flattenStrapiCollection,
	flattenStrapiEntity,
	flattenStrapiSingleResponse,
	GlobalSettings,
	StrapiSingleResponse
} from './global.model';
import { environment } from '../../../../environments/environment';
import { forkJoin } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { scrollToElementId } from '../../../_core/utils/dom.utils';
import { Title } from '@angular/platform-browser';
import { groupItemsByParent } from './global.utils';
import qs from 'qs';
import { CMS_LOCALES } from './global.data';
import { LocaleCode } from '../../models/locale';
import { StrapiLocale } from './strapi-locale.model';

@Injectable({ providedIn: 'root' })
export class GlobalService {
	constructor(
		private readonly globalStore: GlobalStore,
		private readonly http: HttpClient,
		private readonly snackBar: MatSnackBar,
		private readonly titleService: Title
	) {}

	get() {
		const authHeaders = {
			Authorization: `Bearer ${environment.cmsToken}`
		};
		let authentication = this.http.get<StrapiSingleResponse<any>>(`${environment.cmsUrl}/api/authentication`, {
			headers: authHeaders
		});

		const query = qs.stringify({
			populate: {
				creativePackageCategories: {
					populate: 'parent'
				},
				paymentMethods: {
					populate: '*'
				},
				products: {
					populate: '*'
				},
				organizationLogo: {
					populate: '*'
				},
				loginPageImage: {
					populate: '*'
				},
				signupPageImage: {
					populate: '*'
				}
			}
		});
		let orgSettings = this.http.get<StrapiSingleResponse<any>>(`${environment.cmsUrl}/api/organization-setting?${query}`, {
			headers: authHeaders
		});

		const siteCopyQuery = qs.stringify({
			locale: this.getCmsLocale(),
			populate: {
				heroGetStartedBackground: {
					populate: '*'
				},
				footerLinks: {
					populate: '*'
				},
				exportProgressMessages: {
					populate: '*'
				}
			}
		});
		let siteCopy = this.http.get<StrapiSingleResponse<any>>(`${environment.cmsUrl}/api/site-copy?${siteCopyQuery}`, {
			headers: authHeaders
		});

		const locales = this.http.get<StrapiLocale[]>(`${environment.cmsUrl}/api/i18n/locales`, { headers: authHeaders });

		return forkJoin([authentication, orgSettings, siteCopy, locales]).pipe(
			map(([authentication, orgSettings, siteCopy, locales]) => {
				const mergedValues = {
					...authentication?.data?.attributes,
					...this.convertStrapiOrgSettings(orgSettings),
					locales
				};

				const siteCopyValues = siteCopy?.data?.attributes || {};
				for (const [key, value] of Object.entries(siteCopyValues)) {
					if (mergedValues[key] && !value) {
						// don't override the value if it's already set and siteCopy has it undefined
					} else {
						mergedValues[key] = value;
					}
				}

				return mergedValues;
			}),
			tap((settings: GlobalSettings) =>
				this.globalStore.update({
					settings
				})
			)
		);
	}

	setState(state: GlobalState['state'], scrollToTop = true) {
		if (scrollToTop && state !== this.globalStore.getValue().state) {
			scrollToElementId('top');
		}

		this.globalStore.update({
			state
		});
	}

	setPages(pages: GlobalState['pages']) {
		this.globalStore.update({
			pages
		});
	}

	resetPages() {
		this.globalStore.update({
			pages: createInitialState().pages
		});
	}

	nextState() {
		scrollToElementId('top');

		// Find the current page and then iterate up
		const currentState = this.globalStore.getValue().state;

		this.globalStore.getValue().pages.forEach((page, i) => {
			if (page.id === currentState) {
				// Found a match, lets go to the next page though
				if (i >= this.globalStore.getValue().pages.length - 1) {
					// Should we go back to the start?
				} else {
					this.globalStore.update({
						state: this.globalStore.getValue().pages[i + 1].id
					});
				}
			}
		});
	}

	generateVideoPreview() {
		this.queueVideoProcessing(true);
	}

	generateVideo() {
		this.queueVideoProcessing(false);
	}

	queueVideoProcessing(watermark: boolean) {
		// Get the Correct story segment clips and any extra properties
		// Get the active music track
		// Get the video format
	}

	/**
	 * Set if the active project has
	 */
	dirtyTick() {
		this.globalStore.update({
			dirtyTick: new Date().toISOString()
		});
	}

	resetDirtyTick() {
		this.globalStore.update({
			dirtyTick: undefined
		});
	}

	setAudioPlaying(id: string) {
		this.globalStore.update({
			audioPlaying: id
		});
	}

	stopAudio() {
		this.globalStore.update({
			audioPlaying: undefined
		});
	}

	startAudioPreviewPlaying() {
		this.globalStore.update({
			audioPreviewPlaying: true
		});
	}

	stopAudioPreviewPlaying() {
		this.globalStore.update({
			audioPreviewPlaying: false
		});
	}

	/**
	 * Toggles the muted state of the editor video in the global store.
	 * @param state - Optional new value for the muted state.
	 * If not provided, the muted state is flipped from its current value.
	 */
	toggleEditorVideoMuted(state?: boolean) {
		this.globalStore.update({
			editorVideoMuted: state || !this.globalStore.getValue().editorVideoMuted
		});
	}

	/**
	 * Set an Admin Mode that triggers some debug UI on the site.
	 * Search adminMode to find all of the places this applies.
	 */
	setAdminMode(state?: boolean) {
		this.globalStore.update({
			adminMode: state || !this.globalStore.getValue().adminMode
		});
	}

	/**
	 * Open a snack bar presenting the error message to the user.
	 * @param err The error response object
	 * @param message Override with a custom message
	 */
	triggerSaveMessage(message?: string) {
		this.snackBar.open(message || $localize`:Save Message@@saveMessageSaveSuccessfulText:Save Successful.`, undefined, {
			verticalPosition: 'bottom',
			horizontalPosition: 'right',
			duration: 2000,
			panelClass: 'success'
		});
	}

	/**
	 * Open a snack bar presenting the success message to the user.
	 * @param message Override with a custom message
	 * @param config Override default config
	 */
	triggerSuccessMessage(message?: string, config?: any, action?: { label: string; callback: () => void }) {
		let snack = this.snackBar.open(message || 'Finished.', action?.label, {
			verticalPosition: 'bottom',
			horizontalPosition: 'center',
			duration: 4000,
			panelClass: 'success',
			...config
		});

		// If an action callback, listen for that event.
		if (action?.callback) {
			snack.onAction().subscribe(() => {
				if (action?.callback) {
					action.callback();
				}
			});
		}
	}

	/**
	 * Open a snack bar presenting the error message to the user.
	 * @param err The error response object
	 * @param message Override with a custom message
	 */
	triggerErrorMessage(err: HttpErrorResponse, message?: string) {

		if(err?.error?.message && err?.error?.message.includes('Email Domain Invalid')) {
			this.snackBar.open(
					$localize`:Error Message@@errorMessageInvalidEmailDomain:Email Address domain is not permitted. Please contact support to have your email address approved.`,
				undefined,
				{
					duration: 4000,
					panelClass: 'danger'
				}
			);
		} else {
			this.snackBar.open(
				err?.error?.message ||
					err?.message ||
					message ||
					$localize`:Error Message@@errorMessageCompletingTaskErrorText:There was an error completing this task.`,
				undefined,
				{
					duration: 4000,
					panelClass: 'danger'
				}
			);
		}

	}

	/**
	 * Open a snack bar presenting the error message to the user.
	 * @param message custom message
	 * @param className custom styles to apply
	 * @param config configuration object for snackbar
	 */
	triggerCustomMessage(message?: string, className?: string, config: any = {}) {
		this.snackBar.open(
			message || $localize`:Error Message@@errorMessageCompletingTaskErrorText:There was an error completing this task.`,
			undefined,
			{
				duration: 4000,
				panelClass: className,
				...config
			}
		);
	}

	/**
	 * Set the Browser title bar message.
	 */
	setTitle(title: string) {
		this.titleService.setTitle(title + ' | ' + this.globalStore.getValue()?.settings?.organizationName);
	}

	/**
	 * Copy the provided text to the clipboard.
	 */
	copyToClipboard(text: string) {
		let textArea = document.createElement('textarea');
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		textArea.remove();

		this.triggerSuccessMessage($localize`:Success Message@@copyToClipboardSuccessMessageText:Copied to clipboard.`);
	}

	/**
	 * Convert the Strapi response into usable object.
	 */
	convertStrapiOrgSettings(orgSettings: StrapiSingleResponse<any>) {
		if (!orgSettings?.data?.attributes) {
			return {};
		}

		// Convert the creative package categories into a usable object.
		let creativePackageCategories = flattenStrapiCollection(orgSettings?.data?.attributes?.creativePackageCategories).map(
			(category: any) => ({
				...category,
				parent: flattenStrapiSingleResponse(category?.parent)
			})
		);

		return {
			...orgSettings?.data?.attributes,
			creativePackageCategories: groupItemsByParent(creativePackageCategories),
			paymentMethodsRequired: orgSettings?.data?.attributes?.paymentMethodsRequired !== false
		};
	}

	getLocale() {
		let code = localStorage.getItem('localeCode');
		const native = navigator.language;

		if (!code) {
			code = native;
			if (code.includes('CN')) {
				return LocaleCode.zhCN;
			}
			if (code.includes('CA')) {
				return LocaleCode.frCA;
			}
		}

		if (!Object.values(LocaleCode).includes(code as LocaleCode)) {
			return LocaleCode.enUS;
		}

		return code;
	}

	getCmsLocale(fallback?: string) {
		const code = this.getLocale();

		return CMS_LOCALES[code] || fallback;
	}
}
