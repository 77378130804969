import { merge } from 'lodash';
import { ObjectUtils } from '../../_core/utils/utils.object';
import { PublicBusiness } from '../../business/business.entity';
import { CreativeUnitPackageDto } from '../models/creative-unit-package.dto';
import { CustomizationOptionDto } from '../models/customization/customization-option.dto';
import { CustomizationUtils } from './customization.utils';
import { LayersUtils } from './layers.utils';
import { CreativeUnitWithCustomizationsDto } from '../models/creative-unit/creative-unit.dto';
import { CustomizationType } from '../models/customization/customization.dto';
import { AssetDto } from '../../asset/models/asset.dto';
import { MergeTags } from '../../_core/utils/utils.merge-tags';

export class MergePropertiesUtils {
	/**
	 * Creates a state of all important package details to be used to easily traverse
	 * the state and find properties to use for merge tags.
	 */
	public static getMergeableProperties(pkg: CreativeUnitPackageDto, business?: PublicBusiness, unit?: CreativeUnitWithCustomizationsDto) {
		let customizations = CustomizationUtils.findAllCustomizations(unit?.customizations || pkg?.customizations || []);

		let state = ObjectUtils.unfreeze({
			customizations: customizations.reduce((acc, customization) => {
				acc[customization.id] = customization;
				return acc;
			}, {}),
			layers: LayersUtils.flattenLayers(unit?.layers || pkg?.layers || []).reduce((acc, layer) => {
				acc[layer.id] = layer;
				return acc;
			}, {}),
			variables: {},
			business: business || {},
			activeCreativeUnit: unit,
		});

		// console.log('State', state);

		// Iterate through each variable and try to apply merge tags to them.
		// This allows us to have downstream variables that are dependent on upstream variables.
		state.variables = this.addVariablesToStateRecursively(
			ObjectUtils.unfreeze(unit?.variables || pkg?.variables || {}),
			state
		);
		// console.log('Merged Variables', state?.variables);

		// Creative Unit variable overrides should override even customization changes.
		// console.log('Checking for variable overrides', unit.variables, unit.variableOverrides);
		if (unit?.variableOverrides) {
			let stateVariables = ObjectUtils.unfreeze(state.variables);
			let unitVariables = ObjectUtils.unfreeze(unit?.variableOverrides);

			// Iterate through each variable and try to apply merge tags to them.
			// This allows us to have downstream variables that are dependent on upstream variables.
			try {
				for (let key in unitVariables) {
					let value = unitVariables[key];
					if (typeof value === 'string') {
						unitVariables[key] = MergeTags.applyMergeTagsToString(value, state);
					}
				}
			} catch (e) {
				console.log('Error applying merge tags to variables', e);
			}

			let mergedVariables = merge(stateVariables, unitVariables);
			state.variables = this.addVariablesToStateRecursively(mergedVariables, state);
			// console.log('Merged Variables after Creative unit overrides', state?.variables);
			// console.log('Merged Variables', unit.variables);
		}

		// Merge customization value variables into the state.
		for (let customization of customizations) {

			// Test for visibility.  We do this inside the loop so that we can retest if the customization ahead of us changed something that now
			// makes this customization visible.

			// Test if the customization parent is visible.
			if (customization.parentId) {
				let parentCustomization = customizations.find(c => c.id === customization.parentId);
				if (parentCustomization && !CustomizationUtils.isCustomizationVisible(parentCustomization, undefined, state)) {
					continue;
				}
			}

			// Test if the customization is visible.
			if (!CustomizationUtils.isCustomizationVisible(customization, undefined, state)) {
				continue;
			}

			let variables = CustomizationUtils.getCustomizationVariables(customization);

			if (variables) {
				let stateVariables = ObjectUtils.unfreeze(state.variables);
				let customizationVariables = ObjectUtils.unfreeze(variables);
				let mergedVariables = merge(stateVariables, customizationVariables);
				state.variables = this.addVariablesToStateRecursively(mergedVariables, state);

				// console.log('Merged Variables after customizations', state?.variables);
			}
		}

		// Now look for creative unit specific overrides.
		if (unit) {
			for (let customization of customizations) {

				// Test for visibility.  We do this inside the loop so that we can retest if the customization ahead of us changed something that now
				// makes this customization visible.

				// Test if the customization parent is visible.
				if (customization.parentId) {
					let parentCustomization = customizations.find(c => c.id === customization.parentId);
					if (parentCustomization && !CustomizationUtils.isCustomizationVisible(parentCustomization, undefined, state)) {
						continue;
					}
				}

				// Test if the customization is visible.
				if (!CustomizationUtils.isCustomizationVisible(customization, undefined, state)) {
					continue;
				}

				let variables = CustomizationUtils.getCustomizationUnitVariables(customization, unit);
				// console.log('Customization Unit Variables', customization.label, variables, customization);

				if (variables) {
					let stateVariables = ObjectUtils.unfreeze(state.variables);
					let customizationVariables = ObjectUtils.unfreeze(variables);
					let mergedVariables = merge(stateVariables, customizationVariables);
					state.variables = this.addVariablesToStateRecursively(mergedVariables, state);

					// console.log('Merged Variables after customizations creative unit overrides', state?.variables);
				} else {
					// console.log('No variables found for customization', customization);
				}
			}
		}

		return state;
	}

	// Iterate through each variable and try to apply merge tags to them.
	// This allows us to have downstream variables that are dependent on upstream variables.
	public static addVariablesToStateRecursively(variables: any, state: any): any {
		for (let key in variables) {
			if (variables.hasOwnProperty(key)) {
				let value = variables[key];
				if (typeof value === 'string') {
					try {
						state.variables[key] = variables[key] = MergeTags.applyMergeTagsToString(value, state);
					} catch (e) {}
				} else if (typeof value === 'object' && value !== null) {
					state.variables[key] = variables[key] = { ...this.addVariablesToStateRecursively(value, state) };
				}
			}
		}

		return variables;
	}

	public static getMergedParams(params: any, mergeTagState: any) {
		return JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(params || {}), mergeTagState));
	}
}
