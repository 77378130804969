import { WordpressObject } from '../wordpress-object.model';
import { createStorySegment, StorySegment } from '../story-segment';
import { Music } from '../music';
import { createSurvey, Survey } from '../survey/survey.model';
import { environment } from '../../../../environments/environment';
import { AssetFilter, flattenStrapiEntity, StrapiCollectionResponse, StrapiEntity, StrapiSingleResponse } from '../global/global.model';
import {
	AIVoice,
	AIVoiceoverStory,
	createAIVoiceoverStory,
	createVoice,
	createVoiceoverPackage,
	VoiceoverPackage
} from '../voiceover/voiceover.model';
import { createOverlay, Overlay } from '../overlay/overlay.model';
import { AILanguage, AILanguageSettings, createLanguage } from "../language";

export interface Story {
	id: any;
	title: string;
	instruction: string;
	storySegments?: any;
	surveys: Survey[];
	videoFormats: VideoFormat[];
	music: Music[];
	overlays: Overlay[];
	canRepositionClips: boolean;
	canResizeSegments: boolean;
	isFeatured: boolean;
	assetFilters: AssetFilter[];
	audioSettings: AudioSettings;
	ui?: StoryUI;
	language: AILanguage;
}

export interface StoryUI {
	overlays: Partial<Overlay>[];
}

export interface AudioSettings {
	enableNoMusicOption: boolean;
	enablePremadeMusicOption: boolean;
	enableNoCoreVoiceoverOption: boolean;
	enablePremadeCoreVoiceoverOption: boolean;
	enableAICoreVoiceoverOption: boolean;
	enableNoEndCardVoiceoverOption: boolean;
	enablePremadeEndCardVoiceoverOption: boolean;
	enableAIEndCardVoiceoverOption: boolean;
	aiVoiceoverStories: AIVoiceoverStory[];
	voiceoverPackages: VoiceoverPackage[];
	voices: CMSVoice[];
	coreVoiceoverOffset: number;
	endCardVoiceoverOffset: number;
}

export interface CMSVoice {
	id: number;
	title: string;
	externalId: string;
	createdAt: string;
	publishedAt: string;
	updatedAt: string;
	stability: number;
	similarity: number;
	defaultVoice: boolean;
	speakerboost: boolean;
	languageConfigs?: AILanguageSettings[];
	modelId: string;
	languageCode: string;
}

export function createStory(strapiEntity: StrapiEntity<Story>, params?: Partial<Story>) {
	const surveys = strapiEntity.attributes.surveys as unknown as StrapiCollectionResponse<Survey>;
	const overlays = strapiEntity.attributes.overlays as unknown as StrapiCollectionResponse<Overlay>;
	const storySegments = strapiEntity.attributes['story_segments'] as unknown as StrapiCollectionResponse<StorySegment>;
	const aiVoiceoverStory = strapiEntity.attributes.audioSettings
		.aiVoiceoverStories as unknown as StrapiCollectionResponse<AIVoiceoverStory>;
	const voiceoverPackages = strapiEntity.attributes.audioSettings
		.voiceoverPackages as unknown as StrapiCollectionResponse<VoiceoverPackage>;
	const voices = strapiEntity.attributes.audioSettings.voices as unknown as StrapiCollectionResponse<AIVoice>;
	const language = strapiEntity.attributes.language as unknown as StrapiSingleResponse<AILanguage>;

	return {
		...flattenStrapiEntity(strapiEntity),
		storySegments: storySegments?.data.map(storySegments => createStorySegment(storySegments as any)) || [],
		overlays: overlays?.data.map(overlays => createOverlay(overlays as any)) || [],
		surveys: surveys?.data.map(surveys => createSurvey(surveys as any, params)) || [],
		audioSettings: {
			...strapiEntity.attributes.audioSettings,
			aiVoiceoverStories: aiVoiceoverStory?.data.map(s => createAIVoiceoverStory(s as any)) || [],
			voiceoverPackages: voiceoverPackages?.data.map(s => createVoiceoverPackage(s as any)) || [],
			voices: voices?.data.map(s => createVoice(s as any)) || []
		},
		language: language?.data ? createLanguage(language?.data as any) : null,
		...params
	} as Story;
}

export interface VideoFormat {
	id: number;
	format: string;
	instruction: string;
	resolutionWidth: number;
	resolutionHeight: number;
	title: string;
	active: boolean;
}

export function createVideoFormat(strapiEntity: StrapiEntity<VideoFormat>, params?: Partial<VideoFormat>) {
	return {
		...flattenStrapiEntity(strapiEntity),
		...params
	} as VideoFormat;
}

export function createOverlayUIState(id: Overlay['id'], params?: Partial<Overlay>) {
	return {
		id,
		fields: [],
		isEnabled: true,
		...params
	} as Partial<Overlay>;
}

export const overlaysQuery = `
	overlays {
		id
		... on overlays_overlays_Entry {
			title
			instruction
			isEnabled
			overlayFields {
				... on overlayFields_field_BlockType {
					id
					horizontalOffset
					instructions
					label
					positionHorizontally
					positionVertically
					verticalOffset
					fieldType
					defaultValue
					required
					textColor
					fontSize
					fontFamily
					textAlign
				}
			}
		}
	}
`;

export function getStoryQuery(siteId: string) {
	return `
		{
			entries(type: "stories", siteId: "${siteId}") {
			... on stories_stories_Entry {
				id
				title
				instruction
				canRepositionClips
				canResizeSegments
				videoFormats {
					id
					title
					... on videoFormats_videoFormats_Entry {
						instruction
						format
						resolutionHeight
						resolutionWidth
					}
				}
				surveys {
				id
				title
				... on surveys_surveys_Entry {
					id
					questions {
					id
					title
					... on questions_questions_Entry {
						id
						questionType
						instruction
						answers {
						... on answers_answers_BlockType {
							id
							label
							filterCriteria
							entityType
							entries {
							id
							}
						}
						}
					}
					}
				}
				}
				storySegments {
				id
				title
				... on storySegments_storySegments_Entry {
					ignoreGlobalOverlay
					insertByDefault
					required
					maximumDuration
					${overlaysQuery}
					clips {
					id
					... on clips_clips_Entry {
						video {
							url
							... on videos_Asset {
							metadata {
								duration
							}
							}
						}
					}
					}
				}
				}
				music {
				id
				title
				... on music_music_Entry {
					id
					musicType
					musicAsset {
					id
					... on music_Asset {
						id
						url
					}
					}
				}
				}
				${overlaysQuery}
			}
			}
		}
	`;
}
