import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { filter, map ,tap} from 'rxjs/operators';
import { VoiceoverStore, VoiceoverState } from './voiceover.store';
import { VoiceoverPackage, VoiceoverPackageConfig } from "./voiceover.model";

@Injectable({ providedIn: 'root' })
export class VoiceoverQuery extends QueryEntity<VoiceoverState> {
	public selectAIVoiceovers$ = this.select(state => {
		return this.getAll().filter(vo => vo.kind === 'ai');
	});

	public selectedVoiceovers$ = this.select('selectedVoiceoverPackageConfig').pipe(
		map(config => Object.keys(config?.segments || {}).map(key => config.segments[key].voiceover))
	);

	/**
	 * Verifies that all voiceovers that need an audio file have one.  Should not be used to completely validate a voiceover package.
	 */
	public allSelectedVoiceoversRendered$ = 
	this.select().pipe(tap(x=> console.log(x)),
		map(
			state =>
				state.selectedVoiceoverPackage &&
				Object.keys(state.selectedVoiceoverPackageConfig?.segments || {})?.every(key => {
					if (state.selectedVoiceoverPackageConfig.segments[key].selectedOption === 'none') {
						return true;
					} else if (state.selectedVoiceoverPackageConfig.segments[key].voiceover) {
						return true;
					}
				})
		)
	);

	/**
	 * Verifies that all of the voiceover segments are valid to move forward.
	 */
	public allSelectedVoiceoversReady$ = this.select().pipe(
		map(
			state =>
				state.selectedVoiceoverPackage &&
				(state.selectedVoiceoverPackageConfig || state.selectedVoiceoverPackage.title == 'No Voiceover') &&
				Object.keys(state.selectedVoiceoverPackageConfig?.segments || {})?.every(key => {
					if (state.selectedVoiceoverPackageConfig.segments[key].selectedOption === 'none') {
						return true;
					} else return state.selectedVoiceoverPackageConfig.segments[key].voiceover &&
						((state.selectedVoiceoverPackageConfig.segments[key].voiceover.targetDuration >
						state.selectedVoiceoverPackageConfig.segments[key].voiceover.audio.duration) ||
						state.selectedVoiceoverPackage.voiceoverSegments.find(vs => vs.slug === key).overrideDurationValidation);
				})
		)
	);

	public getSelectedVoiceoverPackage(): VoiceoverPackage {
		return this.getValue().selectedVoiceoverPackage;
	}

	public getSelectedVoiceoverPackageConfig(): VoiceoverPackageConfig {
		return this.getValue().selectedVoiceoverPackageConfig;
	}

	constructor(protected store: VoiceoverStore) {
		super(store);
	}
}
