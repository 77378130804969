import { JobDefDto } from '../encode/models/job-def.dto';
import { Project, ProjectMetadata, Status } from './project.entity';
import { isAfter } from '../_core/utils/utils.date';
import { Business } from '../business/business.entity';
import { StoryFormat, WorkspaceState } from './models/workspace-state';
import { VideoPreset } from './models/video-preset';
import { Strapi } from '../_core/third-party/strapi';
import { CartItemType, ProjectCartItem } from '../cart/project-cart-item.entity';

export class Utils {
	static FINAL_JOB_TAG = 'final';

	public static stringToSlug(str: string) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
		const to = 'aaaaeeeeiiiioooouuuunc------';
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str
			.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

	public static extractProjectMetadata(workspaceState: WorkspaceState): ProjectMetadata {
		let projectMetadata: ProjectMetadata = {};

		//-> 'survey' -> 'entities' -> '*' -> 'questions' -> 0 -> 'value'

		if (workspaceState?.survey?.entities) {
			const surveys = Object.values(workspaceState?.survey?.entities) || [];
			if (surveys.length) {
				for (const survey of surveys) {
					if (survey?.questions?.length) {
						for (const q of survey.questions) {
							if (q.slug?.match(/.*focus-on.*/i)) {
								if (Array.isArray(q.value)) {
									projectMetadata.focus = q.value[0];
								} else {
									projectMetadata.focus = q.value;
								}
								break;
							}
						}
					}

					if (projectMetadata.focus) {
						break;
					}
				}
			}


			projectMetadata.storyFormat = this.getProjectStoryFormatRaw(workspaceState)?.title;

			projectMetadata.format = this.getProjectStoryRaw(workspaceState)?.title;

			return projectMetadata;
		} else {
			return projectMetadata;
		}
	}

	public static getProjectStoryFormatRaw(workspaceState: WorkspaceState) {
		if (workspaceState?.['story-format']) {
			const active = workspaceState['story-format'].active;
			if (active > 0) {
				if (workspaceState['story-format'].entities[active]) {
					return workspaceState['story-format'].entities[active];
				}
			}
		}
	}

	public static getProjectStoryRaw(workspaceState: WorkspaceState) {
		if (workspaceState?.story) {
			const active = workspaceState.story.active;
			if (active > 0) {
				if (workspaceState.story.entities[active]) {
					return workspaceState.story.entities[active];
				}
			}
		}
	}

	public static getVideoFormatText(storyFormat: any) {
		if (!storyFormat || !storyFormat.title){
			return;
		}

		return (storyFormat.description  || storyFormat.title)?.replace(/<br ?\/?>/g, ' ').trim()
	}

	public static getVideoResolution(storyFormat: StoryFormat) {
		if (!storyFormat || !storyFormat.canvasWidth || !storyFormat.canvasHeight){
			return;
		}

		return storyFormat.canvasWidth + 'x' + storyFormat.canvasHeight

	}

	public static getVideoThumbnailUrl(itemData: WorkspaceState | any): string | undefined {
		let thumbnailUrl: string | undefined;

		if(itemData?.['UI/story-segment']) {
			const segmentId = itemData?.['UI/story-segment']?.ids?.[0] || 1
			thumbnailUrl = itemData?.['UI/story-segment']?.entities?.[segmentId]?.activeClip?.video?.poster;
		}

		return thumbnailUrl;
	}

	public static resolveDotNotationPath(path, obj) {
		return path?.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || this);
	}

	public static replaceMergeTags(str: string, obj: any) {
		// Replace ${} with evaluated variable paths
		return str?.replace(/\${(.*?)}/g, match => {
			return Utils.resolveDotNotationPath(match.replace('${', '').replace('}', ''), obj);
		});
	}

	public static formatUserSubmittedJobDef(jobDef: JobDefDto, tag?: 'final' | 'preview', preset?: string) {
		// These fields are for internal use only.
		// They should be removed from user requests.
		if (jobDef?.meta?.outputFolder) {
			delete jobDef.meta.outputFolder;
		}
		if (jobDef?.meta?.outputName) {
			delete jobDef.meta.outputName;
		}
		if (jobDef.meta?.automaticDuration) {
			delete jobDef.meta.automaticDuration;
		}

		if (process.env.CALLBACK_HOST) {
			jobDef.callbackHost = process.env.CALLBACK_HOST;
		}

		if (!jobDef.video && !jobDef.videos?.length) {
			return new Error(`One of parameter 'video' or 'videos' is required.`);
		}

		if (jobDef.video && !jobDef.videos?.length) {
			jobDef.videos = [jobDef.video];
			delete jobDef.video;
		}

		if (tag) {
			jobDef.tag = tag;
		}

		if (preset) {
			jobDef.videos = jobDef.videos.map(v => ({ ...v, preset }));

			if (VideoPreset.isTile(preset)) {
				for (let video of jobDef.videos) {
					for (let clip of video.clipData) {
						clip.scaleX = 1080;
					}
				}
			}
		}

		return jobDef;
	}

	/**
	 * Get an array of the active clips in a project
	 * @returns Array
	 * @param workspaceState
	 */
	public static getProjectActiveClips(workspaceState: WorkspaceState) {
		return workspaceState['UI/story-segment'].ids.map(
			segmentId => workspaceState['UI/story-segment'].entities[segmentId].activeClip?.video || []
		);
	}

	public static getProjectOverlays(workspaceState: WorkspaceState) {
		if (!workspaceState) return [];
		let overlays = workspaceState['UI/story-segment'].ids.map((segmentId, index) => {
			let overlay = [];
			if (index > 0) {
				// overlay.push(
				// 	project['workspaceState']['story-segment'].ids
				// 		.map(segmentId =>
				// 			project['workspaceState']['story-segment'].entities[segmentId].overlays.find(o => {
				// 				return o.slug === 'end-tag';
				// 			})
				// 		)
				// 		.filter(element => element !== undefined)[0]
				// );
			}
			overlay = overlay.concat(workspaceState['UI/story-segment'].entities[segmentId].activeClip?.overlays);

			return overlay;
		});

		// overlays = overlays.concat([
		// 	project['workspaceState']['story-segment'].ids
		// 		.map(segmentId =>
		// 			project['workspaceState']['story-segment'].entities[segmentId].overlays.find(o => {
		// 				return o.slug === 'end-tag';
		// 			})
		// 		)
		// 		.filter(element => element !== undefined)
		// ]);

		// console.log(
		// 	'erse',
		// 	project['workspaceState']['story-segment'].ids
		// 		.map(segmentId =>
		// 			project['workspaceState']['story-segment'].entities[segmentId].overlays.find(o => {
		// 				return o.slug === 'end-tag';
		// 			})
		// 		)
		// 		.filter(element => element !== undefined)
		// );

		return overlays;
	}

	public static findInvalidUsageTimingClips(activeClips: any) {
		return activeClips
			.map(clip => {
				if (clip.customFields?.usageTiming) {
					let dateArray = clip.customFields?.usageTiming.split('-');
					let startDate = dateArray[0].trim();
					let endDate = dateArray[1].trim();
					let currentDate = new Date();
					if (isAfter(currentDate, endDate) === true) {
						return clip;
					} else {
						return;
					}
				} else {
					return;
				}
			})
			.filter(element => element !== undefined);
	}

	// TODO
	// Function to get selected music
	// Function to get selected Voiceover package (AI Voice, Male Matthew, Normal speed ect)
	// Function to get voice over text super content organized by segment
	// Function to get checkout price of download

	public static getProjectActiveMusic(workspaceState: WorkspaceState) {
		return workspaceState['music'].selectedMusic;
	}

	public static getProjectVoiceoverPackageConfig(workspaceState: WorkspaceState) {
		console.log('voice over here', workspaceState?.['voiceover']);
		// if (!project?.['workspaceState']?.['voiceover']['selectedVoiceoverPackageConfig']) {
		// 	console.log('selectedVoiceoverPackage here', project['workspaceState']['voiceover']['selectedVoiceoverPackage']);
		// 	return project['workspaceState']['voiceover']['selectedVoiceoverPackage'];
		// }
		return workspaceState['voiceover']['selectedVoiceoverPackageConfig'];
	}

	public static getVoiceOverSuperSegments(project: Project) {
		return project['workspaceState']['s']['superSegments'];
	}

	public static getOverlayFields(overlays: any, overlayData: any, storySegmentUI: any, segmentSlug: any, business: Business) {
		console.log('overlays', overlays, '\noverlayData', overlayData);
		// 'overlays' does not persist the enabled state correctly (`workspace -> UI/story-segment -> activeClip -> overlays`)
		// 'overlayData' does, but does not carry the same data (`workspace -> UI/story-segment -> overlays`)
		overlays = overlays.filter(o => overlayData.overlays.find(data => data.id === o.id && data.isEnabled !== false));
		overlayData.overlays = overlayData.overlays.filter(data => data?.isEnabled !== false);

		let overlayDatas = [];
		const mergeTagState = {};
		if (overlayData?.overlays?.length > 0) {
			overlayDatas = overlayData.overlays
				.flatMap(overlay => {
					console.log('overlay here 1', overlay);

					if (overlay?.fields?.length > 0) {
						console.log('overlay here 2', overlay.fields);

						return overlay.fields;
					} else {
						return null;
					}
				})
				.filter(Boolean);
			console.log('Overlay Data', overlayDatas);
		} else {
			overlayDatas = overlays;
			console.log('Overlay Data', overlayDatas);
		}

		overlays
			?.filter(o => !!o)
			.map(overlay => {
				let fields = [];

				let mergedFields = overlay?.fields.map(field => {
					if (field.label) {
						console.log(
							overlayDatas.find(dataField => dataField.id === field.id)?.value ||
								overlays.find(o => o.id === overlay.id)?.fields.find(f => f.id === field.id)?.value ||
								overlay.fields.find(f => f.id === field.id)?.defaultValue
						);

						fields[Utils.stringToSlug(field.label)] =
							overlayDatas?.find(dataField => dataField.id === field.id)?.value ||
							overlays.find(o => o.id === overlay.id)?.fields.find(f => f.id === field.id)?.value ||
							overlay.fields.find(f => f.id === field.id)?.defaultValue;
					}

					return fields;
				});

				console.log('mergedFields', mergedFields);

				//if (mergedFields) {
				//console.log(mergedFields);
				mergeTagState['business'] = business;

				mergeTagState['fields'] = fields;
				segmentSlug.map(s => {
					mergeTagState[s.slug] = { ui: storySegmentUI.entities[s.id] };
				});

				console.log('mergeTagState', mergeTagState);
				console.log('mergedFields', { fields: fields });
				if (overlay.fields.length > 0) {
					let done = overlay?.fields?.map((field, index) => {
						let newMergedTags;
						if (overlay.fields?.[index]?.defaultValue?.includes('{core')) {
							overlay.fields[index].defaultValue = overlay.fields[index].defaultValue.replace(
								'core',
								Object.keys(mergeTagState).find(key => {
									if (key.includes('core')) {
										return key;
									}
								})
							);
						}

						if (overlayDatas?.length > 0) {
							let found = overlayDatas.find(dataField => {
								return dataField.id === field.id;
							});
							console.log('overlay.fields[index]', overlay.fields[index]);
							console.log('found', found);

							if (overlay.fields[index].fieldType === 'image') {
								overlay.fields[index].defaultValue = found?.value?.title || '';

							} else if (overlay.fields[index].fieldType === 'colorPicker') {
								const colorMatch = overlayDatas.find(dataField => dataField.id === field.id);
								overlay.fields[index].defaultValue = colorMatch?.value?.name || 'Default';

							} else if (overlay.fields[index].fieldType !== 'image') {
								console.log('mergeTagState', mergeTagState);
								if (overlay.fields[index]?.defaultValue?.includes('{core')) {
									overlay.fields[index].defaultValue = overlay.fields[index].defaultValue.replace(
										'core',
										Object.keys(mergeTagState).find(key => key.includes('core'))
									);
								}

								if (overlay.fields[index]?.defaultValue?.includes('{business')) {
									overlay.fields[index].defaultValue = overlay.fields[index].defaultValue.replace(
										'business',
										Object.keys(mergeTagState).find(key => key.includes('business'))
									);
								}

								newMergedTags = this.replaceMergeTags(
									found?.value || overlay.fields[index].defaultValue || '',
									mergeTagState
								);

								overlay.fields[index].defaultValue = newMergedTags.includes('undefined')
									? overlay.fields[index].defaultValue
									: newMergedTags;
							}
						}

						newMergedTags = this.replaceMergeTags(overlay.fields[index].defaultValue || '', mergeTagState);

						overlay.fields[index].defaultValue = newMergedTags.includes('undefined')
							? overlay.fields[index].defaultValue
							: newMergedTags;
					});

					console.log('done', done);
				}
				//}
			});
		//}

		console.log('overlayData overlays', overlays);

		return overlays;
	}

	public static getSegmentVoiceOverAndSuper(voiceoverPackageConfigSegments: any, segment: any, video?: any): any {
		// test
		console.log('getSegmentVoiceOverAndSuper', video);
		let relatedVoiceoverSlugsArray = [];
		if (segment?.relatedVoiceoverSlugs?.length) {
			relatedVoiceoverSlugsArray = segment.relatedVoiceoverSlugs.split(',');
		}

		let TextArray = relatedVoiceoverSlugsArray.map((relatedVoiceoverSlug, index) => {
			const voiceover = voiceoverPackageConfigSegments?.[relatedVoiceoverSlug];
			console.log('voiceover here 1', voiceover);
			if (voiceover) {
				console.log('voiceover', voiceover);
				return {
					label: 'Voiceover (' + voiceover?.voiceover?.audio?.name + ')',
					voiceText: voiceover.voiceText,
					duration: voiceover.voiceover?.targetDuration || video?.duration
				};
			}
		});

		let superArray = [];
		// Identify if any of the videos have supers
		if (video?.customFields?.supers) {
			superArray.push({ superText: video?.customFields?.supers });
		}

		if (TextArray && superArray && superArray.length > 0) {
			// test
			return {
				textArray: TextArray,
				superArray: superArray,
				customFields: video?.customFields
			};
		} else if (TextArray && superArray.length === 0) {
			// test
			return {
				textArray: TextArray,
				superArray: [],
				customFields: video?.customFields
			};
		}
	}

	public static getProjectSegments(workspaceState: any): any {
		const segments = workspaceState?.['story-segment'];

		if (!segments?.entities) {
			return;
		}

		return Object.values(segments?.entities);
	}

	public static format(inputDate) {
		let date, month, year;

		date = inputDate.getDate();
		// month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();

		month = inputDate.toUTCString().split(' ')[2];
		date = date.toString().padStart(2, '0');

		return `${month}-${date}-${year}`;
	}

	public static getVoiceoverTexts(segments: any): string[] {
		if (!segments) {
			return [];
		}

		return Object.values(segments).map(segment => segment?.['voiceText']);
	}

	public static getProjectDuration(project: Project) {
		let storyId =
			project['workspaceState']['UI/story-segment'].entities[project['workspaceState']['UI/story-segment'].ids[0]]?.activeClip
				?.storyId;

		return project['workspaceState']['story']?.entities[storyId]?.title;
	}

	public static getVehicleName(workspaceState: WorkspaceState) {
		let videos = workspaceState['UI/story-segment'].ids.map(segmentId => {
			return workspaceState['UI/story-segment'].entities[segmentId].activeClip?.video?.customFields?.model;
		});
		if (videos[0]) {
			return videos[0];
		} else {
			return '';
		}
	}

	public static getStoryFormat(project: Project) {
		let storyId =
			project['workspaceState']['UI/story-segment'].entities[project['workspaceState']['UI/story-segment'].ids[0]]?.activeClip
				?.storyId;

		return project['workspaceState']['story']?.entities[storyId]?.title;
	}

	public static getVoiceoverTargetDurationString(segments: any): string {
		if (!segments) {
			return '';
		}

		return Object.values(segments)
			.map(segment => segment['voiceover']?.targetDuration)
			.filter(v => !!v)
			.join('/');
	}

	public static composeReceiptSegmentString(segment: any, clip: any) {
		//let done = segments.map(segment => {
		let title = '\n# ' + segment?.title + '\n\n';

		let voiceOverString = '\n';
		let clipsString = '\n## Clips \n';
		clipsString += '__Clip__: ' + clip.name + '\n';

		if (segment) {
			if (segment.voiceoverText.textArray.length > 0 && segment.voiceoverText.textArray[0]?.voiceText.length > 0) {
				voiceOverString += '\n ### Voiceover \n';
			}

			let voiceDone = segment.voiceoverText.textArray.map(text => {
				console.log('text', text);

				if (text?.voiceText) {
					text.voiceText = text.voiceText
						.replace(/<[^>]*>/g, '')
						.replace(/\s{2,}/g, '')
						.trim();

					voiceOverString += '__' + text.label + '__ (' + text.duration + 's): ' + text.voiceText + '\n\n';

					return '\n\n' + voiceOverString;
				} else {
					return 'No Voiceover';
				}
			});

			console.log('voiceDone', voiceDone);

			if (segment.voiceoverText.superArray.length > 0) {
				voiceOverString += '\n ### Super \n';
			}

			let superDone = segment.voiceoverText.superArray.map(text => {
				if (text.superText) {
					voiceOverString += '\n' + text.superText + '\n\n';
					return voiceOverString;
				}

				return;
			});

			console.log('superDone', superDone);

			if (
				segment.overlays &&
				segment.overlays[0]?.fields &&
				segment.overlays[0]?.fields.length > 0 &&
				segment.overlays[0]?.fields.some(field => field.defaultValue)
				// && segment.overlays[0]?.fields[0].defaultValue
			) {
				voiceOverString += '\n ## ' + segment.title + ' Inputs \n';
				console.log('voiceOverString', voiceOverString);
			}

			segment?.overlays?.map(overlay => {
				if (overlay.fields && overlay.fields.length > 0) {
					overlay.fields.map(field => {
						console.log('field.label && field.defaultValue', field.label && field.defaultValue);

						if (field.label && field.defaultValue) {
							console.log('field.label', field.label);
							console.log('field.defaultValue', field.defaultValue);

							voiceOverString += '__' + field.label + '__: ' + field.defaultValue + '\n\n';
						}
					});
				}
			});

			return String(title + clipsString + voiceOverString);
		}
		//});
	}

	public static composeReceiptClipsString(clips: any, segments: any) {
		let done = clips.map((clip, index) => {
			return this.composeReceiptSegmentString(segments[index], clip);
		});

		if (done) {
			console.log('composeReceiptClipsString done', done);
			return done;
		}
	}

	/**
	 * Returns the json array or htmlString that is used for the project checkout table.
	 * @param project
	 * @returns Object { htmlString: String, jsonArray: Array }
	 */
	public static async composeProjectCheckoutTable(project: Project) {
		if (project.version !== '1' || !project.workspaceState) {
			return { htmlString: '', jsonArray: [] };
		}

		let storyFormats: StoryFormat[] = await Strapi.getStoryFormats();
		// Get all of the Active Clips from the project.
		let activeClips = this.getProjectActiveClips(project.workspaceState);
		let overlays = this.getProjectOverlays(project.workspaceState).reverse();

		let activeMusic = this.getProjectActiveMusic(project.workspaceState);
		let voiceoverPackageConfig = this.getProjectVoiceoverPackageConfig(project.workspaceState);

		let firstRowString = 'Commercial / Youtube Video Customization';
		let lastRowString = 'Video Render: 1080p h.264 MP4';

		// The start of the table html element
		let htmlString = `<table style="text-align: left; border: none; border-collapse: collapse; width: 100%; font-size: 0.9rem;" role="presentation" role="presentation" cellspacing="2">`;
		// First element in the table "Commercial / Youtube Video Customization"
		let htmlVideoType =
			`<tr style=" border: none; border-top: 1px solid rgba(255, 255, 255, 0.1); line-height: 1.8em; " > <td>
		<div class="line-item"> <p style=" padding: 0 15px;" class="name">
		 ` +
			firstRowString +
			`</p> </div>
		</td> </tr>`;
		// The last element in the table "Video Render: 1080p h.264 MP4"
		let htmlEncodeType =
			`<tr style=" padding: border: none; border-top: 1px solid rgba(255, 255, 255, 0.1); line-height: 1.8em; " > <td>
		<div class="line-item">
			<p style="  padding: 0 15px;" class="name">
				` +
			lastRowString +
			`
			</p>
		</div>
		</td> </tr>`;

		// closing tag of the table
		let htmlEndString = `</table>`;
		htmlString += htmlVideoType;
		let jsonArray = [];

		jsonArray.push({ caption: firstRowString });

		// for each active clip create a table row and column and populate with the clips caption and usageTime meta data value.
		// TODO: Type clip properly.
		let processedTable = activeClips.map((clip: any) => {
			// if the usageTiming value exists.
			if (clip.customFields?.usageTiming) {
				htmlString +=
					`<tr style="border: none; border-top: 1px solid rgba(255, 255, 255, 0.1); line-height: 1.8em;" > <td style="padding: 0 35px;"> <div class="nested-line-item"><p style=" padding: 0 15px;" class="name">Clip: ` +
					clip.customFields?.caption +
					`
					(Usage Window: ` +
					clip.customFields?.usageTiming +
					`) </p></div>` +
					`</td></tr>`;
				jsonArray.push({ usageTiming: clip.customFields?.usageTiming, caption: clip.customFields?.caption });
			} else {
				// else the usageTiming value does not exist.
				htmlString +=
					`<tr style="border: none; border-top: 1px solid rgba(255, 255, 255, 0.1); line-height: 1.8em;" > <td style="padding: 0 35px;">
					<div class="nested-line-item ">
					<p style=" padding: 0 15px;" class="name">
					Clip: ` +
					clip.customFields?.caption +
					`
					</p>
					</div>
					</td></tr>`;

				jsonArray.push({ usageTiming: clip.customFields?.usageTiming, caption: clip.customFields?.caption });
			}
		});

		if (processedTable) {
			jsonArray.push({ caption: lastRowString });

			// merge the strings together after processing the active clips rows.
			htmlString += htmlEncodeType;
			htmlString += '<tr style="border: none; border-top: 1px solid rgba(255, 255, 255, 0.1)" ><td></td></tr>';
			htmlString += htmlEndString;

			function replaceMergeTags(str: string, obj: any) {
				console.log('replaceMergeTags str', str);
				// Replace ${} with evaluated variable paths
				return str?.replace(/\${(.*?)}/g, match => {
					return this.resolveDotNotationPath(match.replace('${', '').replace('}', ''), obj);
				});
			}

			let segmentSlugs = this.getProjectSegments(project.workspaceState).map((segment, index) => {
				return { id: segment.id, slug: segment.slug };
			});

			segmentSlugs.push({ id: segmentSlugs.length + 1, slug: 'end-tag' });

			let segmentsArray = this.getProjectSegments(project.workspaceState).map((segment, index) => {
				console.log('segment.slug', segment.slug);
				return {
					title: segment.title,
					overlays: this.getOverlayFields(
						overlays[index],
						project.workspaceState['UI/story-segment'].entities[segment.id],
						project.workspaceState['UI/story-segment'],
						segmentSlugs,
						project.business as Business
					),
					voiceoverText: this.getSegmentVoiceOverAndSuper(voiceoverPackageConfig?.segments, segment, activeClips[index])
				};
			});

			console.log(project.workspaceState['UI/story-segment'].entities[2]?.activeClip?.duration);

			console.log('model', project.workspaceState['UI/story-segment'].entities[1]?.activeClip?.video?.customFields);
			return {
				htmlString: htmlString,
				jsonArray: jsonArray,
				overlays: overlays,
				activeClips: activeClips,
				activeMusic: activeMusic,
				voiceoverPackageConfig: voiceoverPackageConfig,
				project: {
					projectId: project.id,
					name: project.name,
					business: {
						...new Business(project.business).toPublic(),
						site: project.business.site
					},
					duration: this.getProjectDuration(project),
					model: this.getVehicleName(project.workspaceState),
					completedDate: this.format(new Date(project.modified)),
					backgroundMusic: activeMusic?.name || 'No Music',
					voiceoverPackage: voiceoverPackageConfig?.selectedVoice?.name
						? 'AI Voice ' +
						  this.getVoiceoverTargetDurationString(voiceoverPackageConfig.segments) +
						  ' (' +
						  voiceoverPackageConfig?.selectedVoice?.name +
						  ')'
						: 'No Voiceover',
					// TODO: Type clip properly
					clips: activeClips.map((clip: any) => {
						return {
							name: clip.name
						};
					}),
					videoRender: '1080p h.264 MP4',
					segments: segmentsArray.reverse(),
					resolution: storyFormats.find(sf => sf.title == project.metadata.storyFormat)?.resolutionText || ''
				}
			};
		}
	}

	public static getProjectStatus(project: Project): Status {
		if (!project) {
			return Status.Draft;
		}

		const finalJob = project.encodeJobs?.find(job => job.tag === Utils.FINAL_JOB_TAG);

		// if the final job has a result, the project is finished.
		if (finalJob && finalJob.encodeTasks?.findIndex(task => !!task.result?.path) > -1) {
			return Status.Finished;
		}

		if (!finalJob && project.approved) {
			return Status.Approved;
		}

		if (!finalJob && !project.approved && project.requiresApproval) {
			return Status.NeedsReview;
		}

		return Status.Draft;
	}

	public static getCartIemMetadata(item: ProjectCartItem) {
		if (!item?.itemData) {
			return {};
		}

		if (item.type === CartItemType.VIDEO){
			const storyFormat = Utils.getProjectStoryFormatRaw(item.itemData);
			return {
				...this.extractProjectMetadata(item.itemData),
				videoFormatText: this.getVideoFormatText(storyFormat),
				videoResolution: this.getVideoResolution(storyFormat),
				thumbnailUrl: item?.metadata?.thumbnail?.assetPath || this.getVideoThumbnailUrl(item.itemData),
				storyTitle: this.getProjectStoryRaw(item.itemData)?.title,
			}
		}

		if (item.type === CartItemType.AD_UNIT) {
			return {
				id: item.itemData.id,
				name: item.itemData.name,
				packageId: item.itemData.packageId,
				dimensions: item.itemData.dimensions,
				templateCreativeUnit: item.itemData.templateCreativeUnit,
				// layers: item.itemData.layers?.map((layer: any) => ({id: layer.id, label: layer.label, type: layer.type, image: layer.image, video: layer.video, text: layer.text})),
				position: item.itemData?.position,
			}
		}

		return {}
	}

	public static dehydrateCartItemMetadata(metadata: any) {
		if (metadata) {
			if (metadata.layers) {
				delete metadata.layers;
			}

			if (metadata.package) {
				const packageMetadata = metadata.package;

				metadata.package = {
					id: packageMetadata.id,
					name: packageMetadata.name,
					tags: packageMetadata.tags,
					thumbnail: packageMetadata.thumbnail,
					exportTypes: packageMetadata.exportTypes
				};
			}
		}

		return metadata || {};
	}
}
