import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { SessionState } from './session.model';
import { SessionStore } from './session.store';

import { BusinessRole, BusinessUser } from '../../../../../../api/src/business/business-user.entity';
import { UserRole } from '../../../../../../api/src/user/user.entity';
import { UserUtils } from '../../../../../../api/src/user/user.utils';

@Injectable({
	providedIn: 'root'
})
export class SessionQuery extends Query<SessionState> {
	isLoggedIn$ = this.select(state => toBoolean(state.token));
	name$ = this.select(state => state.name);
	profile$ = this.select(state => state.profile);

	constructor(protected override store: SessionStore) {
		super(store);
	}

	isLoggedIn() {
		return toBoolean(this.getValue().token);
	}

	getEmailInput() {
		return this.getValue().ui.emailInput;
	}

	getProfile() {
		return this.getValue().profile;
	}

	getBusinesses() {
		return this.getValue().profile?.businesses;
	}

	getToken() {
		return this.getValue().token;
	}

	isReviewer() {
		return this.getValue()?.profile?.reviewer ?? false;
	}

	hasReportingPermission(){
		return this.getValue().profile?.reporting;
	}

	isAtLeastAdmin() {
		const role = this.getValue()?.profile?.role;
		if (!role) {
			return false;
		}
		return UserUtils.isRoleAtLeast(role as UserRole, UserRole.Admin);
	}

	isAtLeastSuperAdmin() {
		const role = this.getValue()?.profile?.role;
		if (!role) {
			return false;
		}
		return UserUtils.isRoleAtLeast(role as UserRole, UserRole.SuperAdmin);
	}

	isOwnerOfBusiness(businessId: string) {
		return this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.role === 'owner' ?? false;
	}

	isFinanceUser(businessId: string) {
		return this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.role === BusinessRole.Finance ?? false;
	}

	isBusinessAdmin(businessId: string) {
		return this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.role === BusinessRole.Administrator ?? false;
	}

	isApproverOfBusiness(businessId: string) {
		return this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.isApprover ?? false;
	}

	hasAdEditorPermission(): boolean {
		// check if at least one business has ad-editor permission
		return !!this.getValue()?.businessUser?.find((user: BusinessUser) => !!user.adEditor);
	}

	canPurchaseForBusiness(businessId: string) {
		return (
			(this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.canPurchase ||
				this.getValue()?.businessUser?.find(user => user.businessesId === businessId)?.role === BusinessRole.Owner ||
				this.getValue()?.profile?.role === UserRole.SuperAdmin) ??
			false
		);
	}
}
