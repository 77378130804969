import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PublicAsset as Asset } from '../../../../../../api/src/asset/asset.entity';
import { Type as AssetCategory } from '../../../../../../api/src/asset/asset.entity';
import { DefaultResponse, SessionQuery } from '../session';
import { AssetStore } from './asset.store';
import { QueryParams } from '../collection/collection.model';
import { BusinessQuery } from "../business";
import { ProjectsQuery } from "../project";

export type FileUploadObject = {
	id?: string;
	title?: string;
	description?: string;
	dimensions?: any;
	mimeType?: string;
	saveTo?: 'my-files' | 'dealer-files' | 'project-files' | 'all-files';
	category?: AssetCategory;
	uploaded?: boolean;
	uploading?: boolean;
	duration?: number;
	file: File;
};

export enum AssetGroupKey {
	userAssets = 'userAssets',
	projectAssets = 'projectAssets',
	businessAssets = 'businessAssets',
	siteAssets = 'siteAssets',
	userProjectExports = 'userProjectExports',
}

@Injectable({ providedIn: 'root' })
export class AssetService {
	private assetListUser: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
	public get assetListUser$(): Observable<Asset[]> {
		return this.assetListUser.asObservable();
	}
	private assetListUserProjectExports: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
	public get assetListUserProjectExports$(): Observable<Asset[]> {
		return this.assetListUserProjectExports.asObservable();
	}
	private assetListProject: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
	public get assetListProject$(): Observable<Asset[]> {
		return this.assetListProject.asObservable();
	}

	private assetListBusiness: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
	public get assetListBusiness$(): Observable<Asset[]> {
		return this.assetListBusiness.asObservable();
	}
	private assetListSite: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
	public get assetListSite$(): Observable<Asset[]> {
		return this.assetListSite.asObservable();
	}

	constructor(
		private readonly http: HttpClient,
		private readonly businessQuery: BusinessQuery,
		private readonly projectsQuery: ProjectsQuery,
		private readonly assetStore: AssetStore,
		private readonly sessionQuery: SessionQuery
	) {}


	public get(params: QueryParams, append?: boolean, returnOnly?: boolean, ...args) {
		let query;

		// look for 'slug' in extra args
		const slug = args[0] as string;

		console.log('slug', slug, 'append', append);

		switch(slug) {
			case 'uploads':
				const user = this.sessionQuery.getProfile();
				const project = this.projectsQuery.getActive();
				let businessId = project?.business?.id ? project?.business?.id : this.businessQuery.getActiveId();

				if (!businessId) {
					businessId = user.businesses[0].id;
				}
				console.log('user', user);
				console.log('businessId', businessId);

				// Convert some params to fit with the API endpoints.
				params = {
					...params,
					pageSize: params.limit,
				};

				delete params.limit;

				return this.http
					.post<DefaultResponse<any>>(`${environment.apiUrl}/asset/find`, {
						businessId, params
					})
					.pipe(
						map(response => {
							console.log('response', response);
							return {
								data: {
									results: response.data.map(asset => ({
										...asset,
										mediaType: this.getMediaTypeFromUrl(asset.assetPath)
									})),
									totalResults: response.totalResults
								}
							};
						}),
					);

			default:
				// Convert some params to fit with the data sources API endpoints.
				params = {
					...params,
					pageSize: params.limit,
				};

				delete params.limit;

				return this.http
					.post<DefaultResponse<any>>(`${environment.apiUrl}/data-source/${slug}/query`, {
						params
					})
					.pipe(
						map((response: DefaultResponse<any>) => {
							return {
								data: {
									cursor: response.data.cursor,
									results: response.data.results.map((asset: Asset) => ({
										...asset,
										mediaType: this.getMediaTypeFromUrl(asset.assetPath)
									})),
									totalResults: response.data.totalResults
								}
							};
						})
					);
		}
	}

	public getMediaTypeFromUrl(url: string) {
		const ext = url?.split('.').pop();
		if (['jpg', 'jpeg', 'png'].includes(ext)) {
			return 'image';
		}
		if (['mp3', 'wav'].includes(ext)) {
			return 'audio';
		}
		if (['mp4', 'mov'].includes(ext)) {
			return 'video';
		}
		if (['ttf', 'woff', 'woff2'].includes(ext)) {
			return 'font';
		}
		return 'file';
	}


	// TODO: expand mime whitelist.
	public isImage(mimeType: string) {
		const types = ['image/jpeg', 'image/png'];
		return types.includes(mimeType);
	}

	public isAudio(mimeType: string) {
		const types = ['audio/mpeg', 'audio/wav'];
		return types.includes(mimeType);
	}

	public isVideo(mimeType: string) {
		const types = ['video/mp4', 'video/quicktime'];
		return types.includes(mimeType);
	}

	public isWebFont(file: File) {
		const types = ['application/x-font-ttf', 'application/x-font-woff', 'application/x-font-woff2'];
		const extensions = ['ttf', 'woff', 'woff2'];
		return types.includes(file.type) || extensions.includes(file.name.split('.').pop() as string);
	}


	// TODO
	public upload(fileReq: FileUploadObject, siteId: string, projectId?: string, businessId?: string,  provider: string = 'amazon') {
		const formData: FormData = new FormData();
		formData.append('file', fileReq.file);
		// formData.append('name', fileReq.file.name);
		formData.append('title', fileReq.title ?? fileReq.file.name);
		formData.append('provider', provider);
		formData.append('type', fileReq.category as string);
		if (fileReq.dimensions) {
			formData.append('width', fileReq.dimensions.width);
			formData.append('height', fileReq.dimensions.height);
		}
		if (fileReq.description) {
			formData.append('description', fileReq.description);
		}
		if (fileReq.saveTo === 'my-files') {
			formData.append('saveToUserFiles', 'true');
		} else {
			if (fileReq.saveTo === 'project-files' && projectId) {
				formData.append('projectId', projectId);
			}

			if (fileReq.saveTo === 'dealer-files' && businessId) {
				formData.append('businessId', businessId);
			}
		}

		return this.http
			.request(
				new HttpRequest('POST', `${environment.apiUrl}/asset/site/${siteId}`, formData, {
					reportProgress: true,
					responseType: 'json'
				})
			)
			.pipe(
				tap(() => {
					this.updateAssetList(siteId, projectId, businessId);
				})
			);
	}

	public remove(id: string, siteId: string, projectId?: string, businessId?: string) {
		return this.http
			.request(
				new HttpRequest('DELETE', `${environment.apiUrl}/asset/site/${siteId}/${id}`, {
					reportProgress: true,
					responseType: 'json'
				})
			)
			.pipe(
				tap(() => {
					this.updateAssetList(siteId, projectId, businessId);
				})
			);
	}

	public updateAssetList(siteId: string, projectId?: string, businessId?: string, loadUserProjectExports?: boolean) {
		/*
		this.http.request<Asset[]>('GET', `${environment.apiUrl}/asset/user`).subscribe((event: Asset[]) => {
			this.assetListUser.next(event);
		});

		 */

		if (loadUserProjectExports){
			this.http.request<Asset[]>('GET', `${environment.apiUrl}/asset/user/projects`).subscribe((event: Asset[]) => {
				this.assetListUserProjectExports.next(event);
			});
		}

		this.http.request<Asset[]>('GET', `${environment.apiUrl}/asset/site/${siteId}`).subscribe((event: Asset[]) => {
			this.assetListSite.next(event);
		});

		if (projectId) {
			this.http.request<Asset[]>('GET', `${environment.apiUrl}/asset/project/${projectId}`).subscribe((event: Asset[]) => {
				this.assetListProject.next(event);
			});
		}

		if (businessId) {
			this.http.request<Asset[]>('GET', `${environment.apiUrl}/asset/business/${businessId}`).subscribe((event: Asset[]) => {
				this.assetListBusiness.next(event);
			});
		}
	}

	assetExists(assetId: string) {
		let assets: Asset[];

		combineLatest([this.assetListProject$, this.assetListBusiness$, this.assetListSite$])
			.pipe(
				map(([assetListProject, assetListBusiness, assetListSite]) => {
					return [...assetListProject, ...assetListBusiness, ...assetListSite];
			})
		).subscribe(a => assets = a);

		return assets.some(asset => asset.id === assetId);
	}
}
