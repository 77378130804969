import { isBoolean } from 'lodash';
import { ObjectUtils } from './utils.object';

/**
 * Evaluates the given condition against the state object.
 * @param condition The condition to evaluate
 * @param state The state object
 * @returns true if the condition is satisfied, false otherwise
 *
 * @example Single condition:
	{
		"path": "customizations.1701051811034.value.value",
		"value": "apr-plus-bonus-cash",
		"relation": "eq"
	}

 * @example Grouped condition:
	{
		"type": "AND",
		"conditions": [
			{
				"path": "customizations.1701051811034.value.value",
				"value": "apr-plus-bonus-cash",
				"relation": "eq"
			},
			{
				"type": "OR",
				"conditions": [
					{
						"path": "customizations.123456789.value",
						"value": "some-value",
						"relation": "eq"
					},
					{
						"path": "customizations.987654321.value",
						"value": "another-value",
						"relation": "neq"
					}
				]
			}
		]
	}
 */
export class Evaluate {
	private static evaluateCondition(condition, state) {
		// Access the value from the state object using the path
		let actualValue = ObjectUtils.resolveDotNotationPath(condition.path, state);

		// Allow boolean values to be used directly in the path.
		if (isBoolean(condition.path)) {
			actualValue = condition.path;
		} else if (condition.path === 'true') {
			actualValue = true;
		} else if (condition.path === 'false') {
			actualValue = false;
		} else if (condition.path === 'undefined') {
			actualValue = undefined;
		}

		if (condition?.value === 'true') {
			condition.value = true;
		}
		if (condition?.value === 'false') {
			condition.value = false;
		}

		// Compare the actual value with the expected value based on the relation
		switch (condition?.relation) {
			case 'eq':
				return actualValue === condition.value;

			case 'neq':
				return actualValue !== condition.value;

			case 'gt':
				return actualValue > condition.value;

			case 'gte':
				return actualValue >= condition.value;

			case 'lt':
				return actualValue < condition.value;

			case 'lte':
				return actualValue <= condition.value;

			case 'contains':
			case 'includes':
				actualValue = actualValue?.toString();
				return actualValue?.includes(condition.value.toString());

			case 'contained-in':
				actualValue = actualValue?.toString();
				return condition.value?.toString().includes(actualValue);

			case 'excludes':
				actualValue = actualValue?.toString();
				return !actualValue?.includes(condition.value.toString());

			case 'undefined':
				return actualValue === undefined;

			case 'not-undefined':
				return actualValue !== undefined;
		}
	}

	private static evaluateConditions(conditions, operator, state) {
		if (operator === 'AND') {
			return conditions.every(cond => this.evaluate(cond, state));
		} else if (operator === 'OR') {
			return conditions.some(cond => this.evaluate(cond, state));
		} else {
			console.error('Invalid operator', operator);
			return false;
		}
	}

	public static evaluate(condition, state) {
		if (condition.type && condition.conditions) {
			// It's a grouped condition
			return this.evaluateConditions(condition.conditions, condition.type, state);
		} else if (condition.path && condition.relation) {
			// It's a single condition
			return this.evaluateCondition(condition, state);
		} else {
			console.error('Invalid condition', condition);
			return false;
		}
	}

	public static evaluateMultiple(conditions, state) {
		// If visibilityConditions are not an array, wrap into an array.
		if (!Array.isArray(conditions)) {
			conditions = [conditions];
		}

		// Iterate over the visibility conditions and check if they are valid.
		for (let condition of conditions) {
			// console.log('Checking visibility conditions', condition, this.evaluate(condition, state), state);
			if (!this.evaluate(condition, state)) {
				return false;
			}
		}

		return true;
	}

	public static evaluateConditionGroupedExample = [
		{
			type: 'AND',
			conditions: [
				{
					path: 'path.to.value',
					value: 'test-value',
					relation: 'eq'
				},
				{
					type: 'OR',
					conditions: [
						{
							path: 'path.to.value',
							value: 'some-value',
							relation: 'eq'
						},
						{
							path: 'path.to.value',
							value: 'another-value',
							relation: 'neq'
						}
					]
				}
			]
		}
	];

	public static evaluateConditionSingleExample = [
		{
			path: 'path.to.value',
			value: 'test-value',
			relation: 'eq'
		}
	];
}
