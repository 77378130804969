import { IsOptional, IsNotEmpty, IsString, IsUUID, IsEmail, IsEnum, IsPhoneNumber } from 'class-validator';
import { SortStrategy, SortString, Status } from '../project.entity';

export class ProjectFindDto {
	@IsOptional()
	@IsUUID('4')
	id?: string;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	name?: string;

	@IsOptional()
	@IsUUID('4')
	userId?: string;

	@IsOptional()
	@IsEmail()
	userEmail?: string;

	@IsOptional()
	@IsPhoneNumber()
	userPhone?: string;

	@IsOptional()
	@IsUUID('4')
	businessId?: string;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	businessName?: string;

	@IsOptional()
	@IsString()
	@IsNotEmpty()
	vehicle?: string;

	@IsOptional()
	@IsEnum(Status)
	status?: Status;

	@IsOptional()
	@IsEnum(SortStrategy)
	sort?: SortStrategy;

	@IsOptional()
	@IsEnum(SortString)
	sortBy?: SortString;
}
