import { NgModule } from '@angular/core';
import { TransformObjectPipe } from './transform-object.pipe';
import { GetLocalePipe } from './get-locale.pipe';
import { TextSingularPluralPipe } from './text-singular-plural.pipe';
import { ArrayOrDefaultPipe } from './array-or-default.pipe';
import { FilterArrayPipe } from './filter-array.pipe';
import { FilterArrayByPipe } from './filter-array-by.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
	declarations: [
		TransformObjectPipe,
		GetLocalePipe,
		TransformObjectPipe,
		TextSingularPluralPipe,
		ArrayOrDefaultPipe,
		FilterArrayPipe,
		FilterArrayByPipe,
		SafeUrlPipe
	],
	providers: [TextSingularPluralPipe],
	exports: [
		TransformObjectPipe,
		GetLocalePipe,
		TransformObjectPipe,
		TextSingularPluralPipe,
		ArrayOrDefaultPipe,
		FilterArrayPipe,
		FilterArrayByPipe,
		SafeUrlPipe
	]
})
export class SharedPipeModule {}
