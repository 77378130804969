<ng-container [formGroup]="form" >
	<div [class.show-locale]="showLocale">
		<mat-form-field
			class="no-underline no-hint no-min-width"
			[class.dark-color]="darkMode"
			[class.form-control]="darkMode"
			[class.form-field]="darkMode"
			[class.full-width]="fullWidth"
			appearance="fill"
			[floatLabel]="floatLabel"
		>
			<mat-label *ngIf="label">{{ label }}</mat-label>
			<input matInput type="text" #textInput [name]="controlName" [formControlName]="controlName" [placeholder]="placeholder" [minlength]="minLength" [maxlength]="maxLength" />
			<mat-icon *ngIf="prefix" matPrefix>{{prefix}}</mat-icon>
		</mat-form-field>

		<app-set-locale-edit-button
			*ngIf="showLocale"
			[localeData]="localeData"
			(localeChange)="localeChange.emit($event)"
		></app-set-locale-edit-button>
	</div>

	<div *ngIf="hint" class="input-hint">{{ hint }}</div>
</ng-container>
