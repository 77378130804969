import { environment } from '../../../../environments/environment';
import { Clip } from '../clip';
import { Music } from '../music';
import { VideoFormat } from '../story';
import { StorySegment } from '../story-segment';
import { Voiceover } from '../voiceover/voiceover.model';
import { StoryFormat } from '../story-format';
import { StrapiLocale } from './strapi-locale.model';

export interface PageMeta {
	id: string;
	name: string;
	valid?: boolean;
	visible?: boolean;
	nextLabel?: string;
	hideNext?: boolean;
	disabledReason?: string;
}

export interface GlobalSettings {
	id: string;
	name: string;
	organizationLogo: StrapiSingleResponse<StrapiMedia>;
	signupPageImage: StrapiSingleResponse<StrapiMedia>;
	loginPageImage: StrapiSingleResponse<StrapiMedia>;
	organizationName: string;
	organizationSlug: string;
	siteTitle: string;
	siteFaviconUrl: string;
	assetProvider: string;
	disclaimer: string;
	defaultClipPath: string;
	defaultLocale: string;
	defaultMusicPath: string;
	defaultVoiceoverPath: string;
	getStartedStoryFormatTitle: string;
	getStartedStoryFormatInstruction: string;
	getStartedVideoFormatTitle: string;
	getStartedVideoFormatInstruction: string;
	getStartedStoryTitle: string;
	getStartedStoryInstruction: string;
	heroGetStartedTitle: string;
	heroGetStartedSubtitle: string;
	heroGetStartedDescription: string;
	heroGetStartedVideo: string;
	heroGetStartedBackground: StrapiSingleResponse<StrapiMedia>;
	finalizeVideoPreviewTitle: string;
	finalizeVideoPreviewInstruction: string;
	customStyles: any;
	terms: string;
	signupCopy: string;
	creativePackageCategories: Category[];
	transcriptDownloadEnabled: boolean;
	reviewProcessEnabled: boolean;
	previewShareLinksEnabled: boolean;
	projectStateExportEnabled: boolean;
	disableCheckout: boolean;
	downloadReceiptEnabled: boolean;
	transcriptDownloadInstructions: string;
	transcriptDownloadHeader: string;
	transcriptDownloadCta: string;
	reviewPreviewHeader: string;
	reviewPreviewInstructions: string;
	previewShareLinksInstructions: string;
	offlinePaymentImportTitle: string;
	offlinePaymentImportInstruction: string;
	offlinePaymentImportDialogTitle: string;
	offlinePaymentImportDialogInstruction: string;
	offlinePaymentFileName: string;
	offlinePaymentOnboardDialogTitle: string;
	offlinePaymentOnboardDialogCopy: string;
	freeTrialEnabled: boolean;
	paymentMethodsRequired: boolean;
	disableTermsPopup: boolean;
	billingVerificationThreshold: number;
	enableIntercom: any;
	disableVersion1Projects: boolean;
	enableHotjar: boolean;
	businessNameLabelPlural: string;
	businessNameLabelSingular: string;
	csvDownloadExportEnabled: boolean;
	metadata: any;
	showInReviewSection: boolean;
	showInProgressSection: boolean;
	bodyScripts: string;
	locales: StrapiLocale[];
	signupExtraPrivacyConditions: boolean;
	signupCrossBorderTransfer: string;
	signupPrivacyPolicy: string;
	footerCopyrightText: string;
	showFooter: boolean;
	footerLinks: ActionLink[];
	exportsRequireApproval: boolean;
	projectPageCheckoutButtonLabel: string;
	projectPageCheckoutDisabledTooltip: string;
	projectPageCheckoutTitle: string;
	checkoutSubtitle: string;
	checkoutButtonLabel: string;
	downloadPageCongratulationsTitle: string;
	checkoutTermsSubtitle: string;
	disableReceiptButton: boolean;
	enableNewCreativePackageRequests: boolean;
	disableProjectHoverOverlay: boolean;
	creativePackageRequestFormId: string;
	hideSignUpButton: boolean;
	hideBusinessAddButton: boolean;
	enableCustomExportFileNames: boolean;
	exportProgressMessages: TextItem[];
	enableCreativePackageTerms: boolean;
	creativePackageTerms: string;
	enableExportActions: boolean;
	showDuplicateTemplateButton: boolean;
	enableContactSupportNavItem: boolean;
	contactSupportNavItemTitle: string;
	contactSupportNavItemBody: string;
	emailAuthEnabled: boolean;
	phoneAuthEnabled?: boolean;
	unitPackageListingSizeLabelSingular: string;
	unitPackageListingSizeLabelPlural: string;
	disableUnitSizeDialogVariations: boolean;
	invalidEmailDomainMessage: string;
}

export function getGlobalQuery(siteId: string) {
	return `
		{
			globalSet(siteId: "${siteId}") {
				id
				... on siteSettings_GlobalSet {
					id
					name
					organizationName
					organizationSlug
					organizationLogo {
					url
					}
					loginPageImage {
					url
					}
					signupPageImage {
					url
					}
					getStartedVideoFormatTitle
					getStartedVideoFormatInstruction
					getStartedStoryTitle
					getStartedStoryInstruction
					finalizeVideoPreviewTitle
					finalizeVideoPreviewInstruction
				}
				siteId
			}
		}
	`;
}

export interface GlobalQueryResponse {
	globalSet: GlobalSettings;
}

export interface ValidationError {
	type: string; // Slug-like id for error types
	message: string;
}

export interface EncodeJobObject {
	storyFormat: StoryFormat;
	segments: StorySegment[];
	clips: Clip[]; // TODO
	music: Music;
	voiceovers?: Voiceover[];
}

export interface StrapiSingleResponse<T> {
	data: StrapiEntity<T>;
	meta: StrapiMeta;
}

export interface StrapiCollectionResponse<T> {
	data: StrapiEntity<T>[];
	meta: StrapiMeta;
}

export interface StrapiEntity<T> {
	id: string;
	attributes: T;
}

export interface StrapiMedia {
	alternativeText: string;
	caption: string;
	createdAt: string;
	ext: string;
	formats: any;
	hash: string;
	height: number;
	mime: string;
	name: string;
	previewUrl: string;
	provider: string;
	provider_metadata: any;
	size: number;
	updatedAt: string;
	url: string;
	width: number;
}

export interface StrapiMeta {}

export interface Asset {
	id: string; // Combo of story id, segment id and cms id.
	cmsId?: string; // Id from the External CMS
	storyId?: string;
	segmentId?: string;
	name: string;
	filename: string;
	format: string;
	type: string;
	size: number;
	duration: number;
	assetFilters?: AssetFilter[];
	createdAt: string;
	customFields: { [key: string]: any };
	urls: {
		preview: string;
		original: string;
	};
	isCustomVideo?: boolean;
}

export interface AssetFilter {
	id: number;
	label: string;
	value: string;
	filterCriteria: 'include' | 'exclude';
	entityType: string;
	tags: string;
	overrideParentEntityFilters: boolean;
}

export interface AssetQueryRequest {
	provider: string;
	path: string;
	includeTags?: string; // JSON stringify array of tags to include
	excludeTags?: string; // JSON stringify array of tags to exclude
}

export interface ValueChange {
	key: string;
	value: any;
}

export interface VideoPlayerControl {
	action: 'play' | 'pause' | 'stop' | 'seek' | 'restart';
	seek?: number;
}

export interface Category {
	id: string;
	slug: string;
	name: string;
}

export interface DialogButton {
	caption: string;
	size: string;
	color: string;
	type: string;
	action?: string;
	actionParameter?: string;
}

export interface ActionLink {
	label: string;
	icon: string;
	url?: string;
	linkTarget: '_self' | '_blank' | '_parent' | '_top' | string;
	cssClass: string;
	action?: 'openLink' | 'showTerms' | string;
}

export interface TextItem {
	title: string;
	duration: number;
}

/**
 * This object helps wrap debounced changes so that you can pipe through
 * debounce methods before subscribing and doing something with the value.
 */
export interface DebouncedValueChange extends ValueChange {
	debounceTime: number;
}

export function flattenStrapiCollection<T>(collection: StrapiCollectionResponse<T>): T[] {
	return collection?.data?.map(e => flattenStrapiEntity(e)) || [];
}

export function flattenStrapiSingleResponse<T>(item: StrapiSingleResponse<T>): T {
	return item?.data?.id
		? {
				id: item?.data?.id,
				...item?.data?.attributes
		  }
		: undefined;
}

export function flattenStrapiEntity<T>(entity: StrapiEntity<T>): T {
	return {
		id: entity?.id,
		...entity?.attributes
	};
}
