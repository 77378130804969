import {
	CustomizationGroupDto,
	CustomizationItemDto,
	CustomizationType,
	getCustomizationClassByType
} from '../models/customization/customization.dto';
import { CreativeUnitDto, CreativeUnitWithCustomizationsDto } from '../models/creative-unit/creative-unit.dto';
import { MergeTags } from '../../_core/utils/utils.merge-tags';
import { Evaluate } from '../../_core/utils/utils.evaluate';
import { MergePropertiesUtils } from './merge-properties.utils';
import { CustomizationOptionDto, CustomizationOptionMappingDto } from '../models/customization/customization-option.dto';
import { LayerDto } from '../models/layer/layer.dto';
import { cloneDeep, merge } from 'lodash';
import { ObjectUtils } from "../../_core/utils";
import { String } from '../../_core/utils/utils.string';

export class CustomizationUtils {
	/**
	 * Recursively flatten all customizations into a single array.
	 */
	public static flattenCustomizations(customizations: CustomizationItemDto[]): CustomizationItemDto[] {
		return customizations.reduce<CustomizationItemDto[]>((acc, customization) => {
			acc.push(customization);

			if (customization.type === CustomizationType.GROUP) {
				acc = acc.concat(this.flattenCustomizations(customization.children));
			}

			return acc;
		}, []);
	}

	public static flattenCustomizationsExcludeGroups(customizations: CustomizationItemDto[], parentGroup: string = ""): CustomizationItemDto[] {
		return customizations.reduce<CustomizationItemDto[]>((acc, customization) => {

			if (customization.type === CustomizationType.GROUP) {
				parentGroup += (parentGroup ? " - " : "") + customization.label;
				acc = acc.concat(this.flattenCustomizationsExcludeGroups(customization.children, parentGroup));
			} else {
				customization["parentGroup"] = parentGroup || "No Group";
				acc.push(customization);
			}

			return acc;
		}, []);
	}

	/**
	 * Recursively find all customizations and return them in a flat array.
	 * NOTE: This does not return a boolean like a typical find method.
	 */
	public static findAllCustomizations(customizations: CustomizationItemDto[], parentId?: string): CustomizationItemDto[] {
		let foundCustomizations: CustomizationItemDto[] = [];

		customizations?.forEach(customization => {
			// Add in a parent id to be able to trace back to parent groups.
			if (parentId) {
				customization = {
					...customization,
					parentId: parentId
				};
			}

			foundCustomizations.push(customization);

			if ((customization as CustomizationGroupDto).children) {
				foundCustomizations = foundCustomizations.concat(
					this.findAllCustomizations((customization as CustomizationGroupDto).children, customization.id)
				);
			}
		});

		return foundCustomizations;
	}

	/**
	 * Iterate recursively through our customizations and apply a filter condition to them.
	 * Customizations that pass are retained.  You can also flatten the result into one array.
	 */
	public static filterAllCustomizations(
		customizations: CustomizationItemDto[],
		func: Function,
		flatten?: boolean
	): CustomizationItemDto[] {
		let filteredCustomizations: CustomizationItemDto[] = [];

		// console.log('Starting Filter check', customizations, flatten);

		customizations.forEach(customization => {
			// console.log('Filter Checking', customization.label, func(customization));

			if (func(customization)) {
				if ((customization as CustomizationGroupDto).children) {
					let children = this.filterAllCustomizations((customization as CustomizationGroupDto).children, func, flatten);
					if (flatten) {
						// Flatten the children into the main array
						// console.log('Flattening children', children, filteredCustomizations, filteredCustomizations.concat(children));
						filteredCustomizations = filteredCustomizations.concat(children);
					} else {
						// Add the parent with filtered children
						let newCustomization = {
							...customization,
							children: children
						};
						filteredCustomizations.push(newCustomization);
					}
				} else {
					// No children, just add the customization
					filteredCustomizations.push(customization);
				}
			} else {
				// Well we don't like this customization, but it may have children we like.
				// Only add them if we're flattening though.
				if (flatten && (customization as CustomizationGroupDto).children) {
					filteredCustomizations = filteredCustomizations.concat(
						this.filterAllCustomizations((customization as CustomizationGroupDto).children, func, flatten)
					);
				}
			}
		});

		return filteredCustomizations;
	}

	/**
	 * Iterate recursively through our customizations and apply a map function to them.
	 */
	public static mapAllCustomizations(customizations: CustomizationItemDto[], func: Function) {
		return customizations?.map(customization => {
			const newCustomization = { ...func(customization) };

			if ((newCustomization as CustomizationGroupDto).children) {
				newCustomization.children = this.mapAllCustomizations((newCustomization as CustomizationGroupDto).children, func);
			}

			return newCustomization;
		});
	}

	/**
	 * Iterate recursively through our package customizations and override them with the unit customizations.
	 */
	public static overrideCustomizations(
		packageCustomizations: CustomizationItemDto[],
		unitCustomizations: CustomizationItemDto[]
	): CustomizationItemDto[] {
		return this.mapAllCustomizations(packageCustomizations, (customization: CustomizationItemDto) => {
			const creativeUnitCustomization = unitCustomizations?.find(item => item.id === customization.id);

			return creativeUnitCustomization || customization;
		});
	}

	/**
	 * Move a customization up or down in the list of customizations.
	 * Customizations are moved within their parent group, or if they are not found, they are moved within the root.
	 */
	public static moveCustomization(
		customizations: CustomizationItemDto[],
		movedCustomization: CustomizationItemDto,
		direction: -1 | 1
	): CustomizationItemDto[] {
		let newCustomization: CustomizationItemDto[] = [...customizations];
		const isMovedCustomizationInCurrentLayer = newCustomization.includes(movedCustomization);

		if (isMovedCustomizationInCurrentLayer) {
			const customizationIndex = newCustomization.findIndex(c => c.id === movedCustomization.id);
			const newIndex = customizationIndex + direction;

			if (newIndex >= 0 && newIndex < newCustomization.length) {
				[newCustomization[customizationIndex], newCustomization[newIndex]] = [
					newCustomization[newIndex],
					newCustomization[customizationIndex]
				];
			}
		} else {
			// if not, then recurse through the children until we find the customization
			newCustomization.forEach(c => {
				if (c.type === CustomizationType.GROUP && c.children) {
					c.children = this.moveCustomization(c.children, movedCustomization, direction);
				}
			});
		}

		return newCustomization;
	}

	/**
	 * Looks through the visibility conditions of a customization and determines
	 * whether this customization should be visible to the end user based on the current conditions.
	 */
	public static isCustomizationVisible(customization: CustomizationItemDto, unit: CreativeUnitWithCustomizationsDto, additionalMergeState?: { [key: string]: any }): boolean {
		let state = {};

		if (unit) {
			state = {
				...state,
				...MergePropertiesUtils.getMergeableProperties(undefined, undefined, unit)
			};

			// console.log('Unit state', state);
		}

		if (additionalMergeState) {
			state = {
				...state,
				...(additionalMergeState || {})
			};
		};

		// if (customization.id === '1707157036640') {
		// 	console.log('Checking visibility conditions', customization, state, unit, additionalMergeState);
		// }


		if (customization.type === CustomizationType.DIMENSIONS && !additionalMergeState?.activeCreativeUnit) {
			return false;
		}

		if (customization.visibilityConditions) {
			let visible = true;

			// If visibilityConditions are not an array, wrap into an array.
			let conditions = customization.visibilityConditions;
			if (!Array.isArray(conditions)) {
				conditions = [conditions];
			}

			let mergedConditions;
			// Apply merge tags to the condition.
			try {
				mergedConditions = JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(conditions), state));
			} catch (e) {
				console.warn('Unable to parse condition merge tags');
			}

			if (mergedConditions) {
				visible = Evaluate.evaluateMultiple(mergedConditions, state);
			}

			// for (let condition of conditions) {
			// 	if (customization.id === '1707157036640') {
			// 		console.log('Checking condition', condition, Evaluate.evaluate(condition, state), state);
			// 	}

			// 	// Apply merge tags to the condition.
			// 	try {
			// 		condition = JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(condition), state));
			// 	} catch (e) {
			// 		console.warn('Unable to parse condition merge tags');
			// 	}

			// 	if (!Evaluate.evaluate(condition, state)) {
			// 		visible = false;
			// 		break;
			// 	}
			// }

			if (customization.id === '1707157036640' && !visible) {
				console.log('Condition result', visible, mergedConditions, state);
			}

			return visible;
		} else {
			// console.log('No visibility conditions, defaulting to true');
			return true;
		}
	}

	public static getCustomizationVariables(customization: CustomizationItemDto) {
		let variables = (customization.value as CustomizationOptionDto)?.variables;


		// Find variables in other places.
		switch(customization.type) {

			case CustomizationType.TEXT:
				if (customization.variableKey) {
					variables = { [customization.variableKey]: customization.value };
				}
				break;

			case CustomizationType.SLIDER:
				// Support the single and range slider types.
				if (customization.variableKey) {
					if (customization.template === 'range') {
						variables = { [customization.variableKey]: customization.value?.[0] };
					} else {
						variables = { [customization.variableKey]: customization.value };
					}
				}

				if (customization.variableRangeKey) {
					variables = { [customization.variableRangeKey]: customization.value?.[1] };
				}
				break;

			case CustomizationType.IMAGE_PICKER:
			case CustomizationType.VIDEO_PICKER:
			case CustomizationType.AUDIO_PICKER:
				if (customization.mappings?.variables) {
					let value = customization.value;
					// Video and Audio have slightly different value structures.  The asset gets stored in a "file" property.
					if (customization.type === CustomizationType.VIDEO_PICKER ||
						customization.type === CustomizationType.AUDIO_PICKER) {
						value = value?.file;
					}

					try {
						let variablesString = JSON.stringify(customization.mappings?.variables);
						variables = JSON.parse(MergeTags.applyMergeTagsToString(variablesString, value));
					} catch(e) {}
				}
			break;

			case CustomizationType.COLOR_PICKER:
				if (customization.mappings?.variables) {
					try {
						const variablesString = JSON.stringify(customization.mappings?.variables);
						variables = {...JSON.parse(MergeTags.applyMergeTagsToString(variablesString, customization.value)), ...variables};
					} catch(e) {}
				}
				break;

			default:
				break;
		}

		return variables;
	}

	public static getCustomizationUnitVariables(customization: CustomizationItemDto, creativeUnit: CreativeUnitDto) {
		const value = customization.mappings || customization.value as CustomizationOptionMappingDto;
		let foundUnit = null;

		if (value) {
			// Check if there are any creative overrides specifically for this creative unit within the select option.
			foundUnit = value.creativeUnits?.find(cu =>
				cu.creativeUnitId === creativeUnit.creativeUnitId ||
				cu.label === creativeUnit.name
			);
		}

		return foundUnit?.variables;
	}

	/**
	 * Recursively find all of the customizations who should be visible based on the current conditions
	 * of the Creative Unit.
	 */
	public static getAllVisibleCustomizations(
		customizations: CustomizationItemDto[],
		unit?: CreativeUnitWithCustomizationsDto,
		additionalMergeState?: { [key: string]: any },
		editorMode?: boolean
	): CustomizationItemDto[] {
		return CustomizationUtils.filterAllCustomizations(customizations, customization => {
			// If this customization has a parentId, we need to check if the parent is visible.
			if (customization.parentId) {
				const parent = customizations.find(c => c.id === customization.parentId);
				if (!parent) {
					return false;
				}

				if (!CustomizationUtils.isCustomizationVisible(parent, unit, additionalMergeState)) {
					return false;
				}
			}

			// Don't show this if we aren't in the editor and this is true.
			if (customization.onlyShowInEditor && !editorMode) {
				return false;
			}

			return CustomizationUtils.isCustomizationVisible(customization, unit, additionalMergeState);
		});
	}

	/**
	 * Remove a customization from a creative unit.
	 */
	public static removeCustomization(
		creativeUnit: CreativeUnitWithCustomizationsDto,
		customizationId: string
	): CreativeUnitWithCustomizationsDto {
		// Remove the customization from the creative unit or from its parent customization.
		let newUnit = {
			...creativeUnit,
			customizations: creativeUnit.customizations
				.filter(c => c.id !== customizationId)
				.map(c => {
					if ((c as CustomizationGroupDto).children) {
						return {
							...c,
							children: (c as CustomizationGroupDto).children.filter(item => item.id !== customizationId)
						};
					}

					return c;
				})
		};

		return newUnit;
	}

	public static applyCustomizationConfig(customizations: CustomizationItemDto[], config: { [key: string]: string }) {
		return customizations.map(customization => {
			if (customization.type === CustomizationType.GROUP) {
				customization.children = this.applyCustomizationConfig(customization.children, config);
				return customization;
			}

			const value = config[customization.id];
			if (value === undefined) {
				return customization;
			}
			const customizationClass = getCustomizationClassByType(customization.type);

			return customizationClass?.applyStringValue(customization as any, value);
		});
	}

	public static applyCustomizationValueToLayer(
		value: any, // CustomizationItemDto['value']
		layer: LayerDto,
		creativeUnit?: CreativeUnitDto
	) {
		// Check for any layer overrides on this customization value.
		let newLayer = value.layer?.id === layer?.id ? value.layer : (value?.mappings || value)?.layers?.find(l => l.id === layer.id);
		// console.log('Looking for Layer', layer, newLayer);

		// Next, check if there are any creative overrides specifically for this creative unit within the select option.
		// If so, deep override from the package level option overrides.

		// Clear any empty values out that we know we don't want to pass on.
		if (String.isEmpty(newLayer?.optimizations?.targetCrop?.th)) {
			newLayer = {
				...newLayer,
				optimizations: {
					...newLayer?.optimizations,
					targetCrop: {
						...newLayer?.optimizations?.targetCrop,
						th: undefined
					}
				}
			}
		}

		if (String.isEmpty(newLayer?.optimizations?.targetCrop?.tw)) {
			newLayer = {
				...newLayer,
				optimizations: {
					...newLayer?.optimizations,
					targetCrop: {
						...newLayer?.optimizations?.targetCrop,
						tw: undefined
					}
				}
			}
		}

		if (String.isEmpty(newLayer?.optimizations?.targetCrop?.tx)) {
			newLayer = {
				...newLayer,
				optimizations: {
					...newLayer?.optimizations,
					targetCrop: {
						...newLayer?.optimizations?.targetCrop,
						tx: undefined
					}
				}
			}
		}

		if (String.isEmpty(newLayer?.optimizations?.targetCrop?.ty)) {
			newLayer = {
				...newLayer,
				optimizations: {
					...newLayer?.optimizations,
					targetCrop: {
						...newLayer?.optimizations?.targetCrop,
						ty: undefined
					}
				}
			}
		}

		// Start by matching ids.  This will work in the editor but will fail in end user mode because the items
		// have generated ids
		let foundUnit = value.creativeUnits?.find(cu =>
			cu.id === creativeUnit.id
		);

		// If not, try to match the creative unit id, which should work for the end user mode.
		if (!foundUnit) {
			foundUnit = value.creativeUnits?.find(cu =>
				cu.creativeUnitId === creativeUnit.creativeUnitId
			);
		}

		// If not, try to match the creative unit name as a last resort.
		if (!foundUnit) {
			foundUnit = value.creativeUnits?.find(cu =>
				cu.label === creativeUnit.name
			);
		}

		// console.log('Looking for Unit', value.creativeUnits, creativeUnit.id, foundUnit);
		if (foundUnit) {
			// console.log('Found Unit', foundUnit, layer);
			const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
			if (unitLayer) {
				// console.log('Fount Unit Layer, Merging', newLayer, unitLayer);
				newLayer = merge(cloneDeep(newLayer || {}), unitLayer);
			}
		}

		if (newLayer) {
			return merge(cloneDeep(layer), newLayer);
		} else {
			return layer;
		}
	}

	/**
	 * Remove any invalid layer references from the customizations by comparing against a list of layers.
	 * We expect the layers to already be flattened.
	 */
	public static removeInvalidLayerReferences(customizations: CustomizationItemDto[], layers: LayerDto[]) {
		function filterLayerRefs(property: any) {
			return property.filter((layer: any) => layers.some(l => l.id === layer.id)) || [];
		}

		return CustomizationUtils.mapAllCustomizations(customizations, c => {
			const newCustomization = ObjectUtils.unfreeze(c);

			if (c.layerIds?.length) {
				newCustomization.layerIds = c.layerIds.filter((id: string) => layers.some(l => l.id === id)) || [];
			}

			if (c.mappings?.layers?.length) {
				newCustomization.mappings.layers = filterLayerRefs(c.mappings.layers);
			}

			if (c.value?.layers?.length) {
				newCustomization.value.layers = filterLayerRefs(c.value.layers);
			}

			if (c.value?.mappings?.layers?.length) {
				newCustomization.value.mappings.layers = filterLayerRefs(c.value.mappings.layers);
			}

			if (c.options?.length) {
				newCustomization.options = c.options.map((option: any) => {
					if (option.layers?.length) {
						return {
							...option,
							layers: filterLayerRefs(option.layers)
						}
					} else if (option.layer) {
						return {
							...option,
							layer: layers.some(l => l.id === option.layer.id) ? option.layer : undefined
						}

					} else return option;
				})
			}

			return newCustomization;
		});
	}
}
