import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { compareWithSomething } from '../../../../_core/state/global/global.utils';
import { LocaleDto } from "../../../../../../../api/src/creative-unit-package/models/locale.dto";

@Component({
	selector: 'app-select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectInputComponent extends BaseInputComponent implements OnInit, OnChanges {
	@Input() italicPlaceholder: string | null;
	@Output() openedChange = new EventEmitter<boolean>();

	@Input() showLocale: boolean = false;
	@Input() localeData: LocaleDto;
	@Input() showSearch: boolean = false;
	@Input() searchKeys: string[]; // Keys to search on the options, if not provided will search on the option itself
	@Output() localeChange = new EventEmitter<string>();

	public compareWithSomething = compareWithSomething;

	public searchString: string = '';
	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;

	constructor() {
		super();
	}

	getLocale(value: string, localeData: any): string {
		let locale = window.navigator.language;
		if (localStorage.getItem('localeCode')) {
			locale = localStorage.getItem('localeCode');
		}

		// See if the localeData has a value for the current locale.
		if (localeData && localeData[locale]) {
			return localeData[locale];
		} else {
			return value;
		}
	}

	onKey(value) {
		this.searchString = value;
	}

	searchFn(option: any, searchEnabled: boolean, searchString: string, optionLabelKey: string, searchAttributes: string[]): boolean {
		if (!searchEnabled) {
			return true;
		}

		let toSearch: string = '';

		if (searchAttributes?.length) {
			toSearch = searchAttributes.map(attr => option[attr]?.toString()?.toLowerCase() || '').join(' ');
		}

		if (!toSearch && optionLabelKey) {
			toSearch =  option[optionLabelKey]?.toLowerCase();
		}

		if (!toSearch) {
			toSearch = option?.toLowerCase();
		}

		if (!toSearch) {
			return true;
		}

		return toSearch.includes(searchString.toLowerCase());
	}

	onOpenChange(opened: boolean) {
		this.openedChange.emit(opened);

		if (this.showSearch && this.searchInput?.nativeElement){
			// Clear the search string when the dropdown is closed
			this.searchString = '';
			this.searchInput.nativeElement.value = '';
			if (!this.currentValue) {
				this.searchInput?.nativeElement.focus();
			}
		}
	}



}
