<h1 mat-dialog-title *ngIf="!this.data.remove">{{ data.title }}</h1>
<h1 mat-dialog-title *ngIf="this.data.remove">
	<ng-container i18n="Dialog Title@@confirmDialogRemoveEntityTitle">Remove</ng-container> {{ data.entityName }}</h1>

<div mat-dialog-content>
	<p *ngIf="this.data.unlink" i18n="Unlink Message@@confirmDialogUnlinkProgramMessage">
		Are you sure you want to unlink this program from your plan? This will not remove the program for other plans.
	</p>

	<p *ngIf="this.data.message" [innerHTML]="this.data.message | safeHtml"></p>

	<p *ngIf="this.data.remove && !this.data.showEntityName" i18n="Remove Message@@confirmDialogRemoveEntityMessage">Are you sure you want to remove this entity? You will not be able to undo this action.</p>
	<p *ngIf="this.data.remove && this.data.showEntityName" i18n="Remove Message@@confirmDialogRemoveEntityNameMessage">Are you sure you want to remove this {{ data.entityName }}? You will not be able to undo this action.</p>

	<ng-container *ngIf="confirmation && data.confirmWithString">
		<p i18n="Confirm Message@@confirmDialogConfirmMessage">
			Please type <strong>{{ data.confirmationString }}</strong> below to confirm.
		</p>
		<br />

		<form [formGroup]="confirmation">
			<mat-form-field class="input" fxFlex="100%" appearance="fill" floatLabel="always">
				<mat-label i18n="Verify String Confirm Text@@confirmDialogTypeStringToConfirmText">Type the name to confirm.</mat-label>
				<input matInput name="verifyString" formControlName="verifyString" required (input)="match()" />
			</mat-form-field>
		</form>
	</ng-container>
</div>

<div mat-dialog-actions>
	<button class="cancel secondary-button" mat-flat-button color="secondary" (click)="no()">
		<ng-container *ngIf="this.data?.cancel">{{this.data?.cancel }}</ng-container>
		<ng-container *ngIf="!this.data?.cancel" i18n="Cancel Button@@confirmDialogCancelButtonLabel">Cancel</ng-container>
	</button>
	<button
		*ngIf="confirmation"
		[disabled]="data.confirmWithString && !match()"
		mat-flat-button
		[color]="this.data.remove ? 'warn' : 'primary'"
		[mat-dialog-close]="data"
		class="remove button-extra-small margin-left-auto"
	>
		<span *ngIf="this.data.unlink" i18n="Dialog Button@@confirmDialogUnlinkButtonLabel">Unlink</span>
		<span *ngIf="this.data.confirm">
			<ng-container *ngIf="this.data.confirm">{{ this.data.confirm }}</ng-container>
			<ng-container *ngIf="!this.data.confirm" i18n="Dialog Button@@confirmDialogConfirmButtonLabel">Confirm</ng-container>
		</span>
		<span *ngIf="this.data.remove" i18n="Dialog Button@@confirmDialogRemoveButtonLabel">Remove</span>
	</button>
</div>
