import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { LocaleDto } from '../../../../../../../api/src/creative-unit-package/models/locale.dto';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent extends BaseInputComponent implements OnInit {

	@Input() fullWidth: boolean = false;
	@Input() darkMode: boolean = false;
	@Input() prefix: string;
	@Input() minLength: number;
	@Input() maxLength: number;
	@Input() showLocale: boolean = false;
	@Input() highlightOnFocus: boolean = false;

	@Input() localeData: LocaleDto;
	@Output() localeChange = new EventEmitter<string>();
	@ViewChild('textInput', { static: true }) textInput: ElementRef;

	constructor() {
		super();
	}

	ngOnInit() {
		if (this.highlightOnFocus) {
			this.textInput.nativeElement.addEventListener('focus', this.highlightText);
		}

		super.ngOnInit();
	}

	highlightText(event: FocusEvent) {
		(event.target as HTMLInputElement).select();
	}
}
